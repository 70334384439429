import ControlledSelect from 'components/atoms/Select/Index'
import VirtualInnerList from 'components/atoms/Select/VirtualInnerList'
import { CatalogContext } from 'context/CatalogContext'
import { useContext, useMemo } from 'react'
import { useDefaultStore, useLiveStore } from 'stores'
import { LiveActionTypes } from 'stores/types'
import { Monitors } from 'types/Monitors'
import { expiryToDate } from './utils'

const ExpiryPicker = ({ expiry }: { expiry: string }) => {
  const catalog = useContext(CatalogContext)
  const exchange = useDefaultStore((s) => s.monitors.exchange)
  const ccy = useDefaultStore((s) => s.monitors.currency)
  const dispatch = useLiveStore((s) => s.actions.dispatch)

  const active = useMemo(
    () =>
      Array.from(
        new Set(
          catalog.state.activeOptions.reduce((acc: string[], c) => {
            if (c.exchange === exchange.toLowerCase() && c.baseAsset === ccy) {
              acc.push(c.instrument.split('-')[1])
            }
            return acc
          }, []),
        ),
      ).sort(
        (A, B) =>
          new Date(expiryToDate(A)).getTime() -
          new Date(expiryToDate(B)).getTime(),
      ),
    [catalog.state.activeOptions, exchange, ccy],
  )
  return catalog.state.activeOptions.length > 0 ? (
    <ControlledSelect
      colorShade="blue6"
      value={expiry}
      defaultValue={expiry}
      onValueChange={(v) =>
        dispatch({
          type: LiveActionTypes.SET_MONITOR_IDS,
          data: {
            monitor: Monitors.MARKET_OPTIONS,
            ids: [v],
          },
        })
      }
    >
      <VirtualInnerList data={active} />
    </ControlledSelect>
  ) : (
    <></>
  )
}

export default ExpiryPicker
