import { useQueries, UseQueryOptions } from '@tanstack/react-query'
import { QuoteAsset, Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { CatalogItem, dataService2 as ds } from 'services'
import deepMerge from 'utils/deep-merge'
import { useEffect, useMemo, useRef } from 'react'

function useArrayMemo<T>(array: T[]) {
  // this holds reference to previous value
  const ref = useRef<T[]>()
  // check if each element of the old and new array match
  const areArraysConsideredTheSame =
    ref.current && array.length === ref.current.length
      ? array.every((element, i) => {
          return element === ref.current?.[i]
        })
      : // initially there's no old array defined/stored, so set to false
        false
  useEffect(() => {
    // only update prev results if array is not deemed the same
    if (!areArraysConsideredTheSame) {
      ref.current = array
    }
  }, [areArraysConsideredTheSame, array])
  return areArraysConsideredTheSame ? ref.current : array
}
export type Catalog = Record<
  Source,
  Record<
    AssetClass,
    Record<
      Ticker,
      Record<'active' | 'expired', CatalogItem[]> & {
        expiries?: string[]
        instruments?: string[]
      }
    >
  > & { pairs?: `${Ticker}/${QuoteAsset}`[] }
>

const useCatalog = ({
  assets,
  sources,
  active,
  generateCurrencyPairs,
  listInstruments,
}: {
  assets: AssetClass[]
  sources: Source[]
  active: boolean[]
  generateCurrencyPairs?: boolean
  listInstruments?: boolean
}) => {
  const queries: UseQueryOptions<Catalog, Error>[] = []
  for (let a = 0; a < assets.length; a++) {
    for (let s = 0; s < sources.length; s++) {
      for (let b = 0; b < active.length; b++) {
        queries.push({
          queryKey: [
            'catalog',
            sources[s],
            assets[a],
            active[b],
            generateCurrencyPairs,
            listInstruments,
          ],
          queryFn: () =>
            ds.getCatalogData({
              asset: assets[a],
              source: sources[s],
              active: active[b],
              generateCurrencyPairs,
              listInstruments,
            }),
          refetchInterval: 1000 * 60 * 60, // every hour
          refetchOnMount: false,
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          cacheTime: 60 * 1000 * 60,
        })
      }
    }
  }

  const results = useQueries({
    queries,
  })

  const resData = useArrayMemo(results.map((result) => result.data)) // Current version returns a new array

  const data = useMemo(() => {
    if (resData) {
      return deepMerge(...resData)
    }
  }, [resData])

  const isLoading = results.some((r) => r.isLoading)
  return { isLoading, data }
}

export default useCatalog
