import { useEffect } from 'react'
import { DaysTenor } from 'consts'

import { useDefaultStore, useLiveStore } from 'stores'
import { KeyData, LiveActionTypes } from 'stores/types'
import { Source } from 'types'
import { Monitors } from 'types/Monitors'

import Monitor from '.'

const monitor = Monitors.PARAM_OPTIONS
const ParamsOptionsMonitor = ({ topBar }: { topBar: () => JSX.Element }) => {
  const exchange = useDefaultStore((s) => s.monitors.exchange)
  const ccy = useDefaultStore((s) => s.monitors.currency)
  const dispatch = useLiveStore((s) => s.actions.dispatch)

  const data = useLiveStore((s) => {
    const keys = Object.keys(s.data).filter((k) =>
      k.includes(`${exchange.toLowerCase()}.${ccy}-SABR`),
    )

    return Object.entries(
      keys.reduce((acc: Record<string, KeyData>, id) => {
        const split = id.split('-')
        const tenor =
          DaysTenor[split[split.length - 1].replace('d', '')] ||
          split[split.length - 1]
        acc[tenor] = s.data[id]
        return acc
      }, {}),
    )
  })

  useEffect(() => {
    dispatch({
      type: LiveActionTypes.SUBSCRIBE,
      data: {
        streams: [
          {
            monitor: {
              id: `monitor.${exchange as Source}.${monitor}`,
            },
          },
        ],
      },
    })

    return () =>
      dispatch({
        type: LiveActionTypes.UN_SUBSCRIBE,
        data: {
          streams: [`monitor.${exchange as Source}.${monitor}`],
        },
      })
  }, [dispatch, exchange])

  return (
    <Monitor
      data={data}
      TopBarContent={topBar}
      monitor={Monitors.PARAM_OPTIONS}
      cols={[
        ['vol of vol', 'vv', 'vvdytd', 'vvd24'],
        ['rho', 'rho', 'rhodytd', 'rhod24'],
      ]}
      idColLabel="tenor"
    />
  )
}

export default ParamsOptionsMonitor
