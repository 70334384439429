import { CatalogContext } from 'context/CatalogContext'

import {
  ConstructKey,
  constructKey,
  getFormat,
} from 'components/charts/common/util'
import MultiTimeSeriesBarChart from 'components/charts/MultiSeriesBarChart'
import ChartUIWrapper from 'components/common/ChartWrapper'
import { FREQUENCIES } from 'components/molecules/FrequencyChange'
import { differenceInSeconds, max } from 'date-fns'
import useTimeSeriesQuery from 'hooks/useTimeSeriesQuery'
import { useContext, useEffect, useMemo } from 'react'
import {
  AxisFormat,
  Chart,
  ConstantTenor,
  DerivedOptionQfn,
  Source,
  Ticker,
  TimeSeriesData,
  TimeZone,
  YAxis,
} from 'types'

import { historicTimeseriesDataFetcher } from 'services/queries'

import Modal from 'components/atoms/Modal'
import { usePermissions } from 'hooks/usePermissions'
import useToasts from 'hooks/useToasts'
import { DataFrequency, getRefetchInterval } from 'services'
import { useDefaultStore, useLiveStore } from 'stores'
import {
  ChartState,
  ListedSeries,
  LiveActionTypes,
  SeriesItem,
} from 'stores/types'
import AssetClass from 'types/assetClass'
import { Model, SABRModelMap, SVIModelMap } from 'types/models'
import { getChartTimeStampRange } from 'utils/charts'

import useDerivedActiveListedExpiry from 'hooks/useDerivedActiveListedExpiry'
import { isConstructKey } from 'types/typeGuards'
import { shallow } from 'zustand/shallow'
import ChartLoader from '../../ChartLoader'
import MultiTimeSeriesLineChart from '../../MultiTimeSeriesLineChart'
import AddSeries from './AddSeries'
import { getLiveKey, getLiveTimestamp } from './live'
import { getSeriesAxis } from './utils'

export const getField = (middle: ConstructKey['middle'], field?: string) => {
  if ('series' in middle && middle?.series) {
    return middle?.series
  }
  if ('param' in middle && middle.param) {
    if (middle.model === Model.SVI) {
      return SVIModelMap[middle.param]
    }
    return SABRModelMap[middle.param]
  }

  return field
}

const defaultCurrencies = [Ticker.BTC, Ticker.ETH]
const MultiLineSeriesWrapper = ({
  chartSeries,
  title,
  axisLabel,
  axis,
  type = Chart.TIMESERIES,
  seriesPills,
  field,
  disableMinutes,
  assetType,
  listedExpiry,
  useTypeToggle,
  suffix,
  defaultMiddle,
  currencies = defaultCurrencies,
  sources,
  series,
  useModelParam,
  addVol,
  id,
  useTenors,
  tenors,
  highlightedIndx,
  fetchListedExpiries,
  availableTenors,
}: {
  chartSeries: SeriesItem[]
  axisLabel: string
  axis: Partial<Record<YAxis, AxisFormat>>

  title: string
  type?: Chart
  seriesPills: JSX.Element
  field?: string
  disableMinutes?: boolean
  assetType: AssetClass.FUTURE | AssetClass.OPTION | AssetClass.INDICES
  listedExpiry?: {
    enabled: boolean
    only?: boolean
  }
  useTypeToggle?: boolean
  suffix: string | string[]
  defaultMiddle?: Omit<Partial<ConstructKey['middle']>, 'type'> & {
    type: [string, string] | [string]
  }
  currencies?: Ticker[]
  sources?: Source[]
  series?: string[]
  useModelParam?: boolean
  addVol?: boolean
  id: string
  useTenors?: boolean
  tenors?: ConstantTenor[]
  highlightedIndx?: undefined | number
  fetchListedExpiries?: boolean
  availableTenors?: ConstantTenor[]
}): JSX.Element => {
  const { openToast, ToastComponent, ToastType } = useToasts()
  const idKey = id || title
  const { hasMinuteData } = usePermissions()
  const chartTimings = useDefaultStore((state) => {
    const chart = state.defaultCharts?.[idKey]
    if (chart && 'timings' in chart && 'range' in chart.timings) {
      return chart.timings
    }

    return { single: 'LATEST' } as { single: 'LATEST' }
  })

  const chartTimeFreq = useDefaultStore((s) => {
    const chart = s.defaultCharts?.[idKey]
    if (chart && 'frequency' in chart && chart.frequency) {
      return chart.frequency
    }
    return '1D'
  })

  const chartAxis = useDefaultStore((s) => {
    const chart = s.defaultCharts?.[idKey]
    if (chart && 'axis' in chart && chart.axis) {
      return chart.axis
    }
    return axis
  })

  const chartTimeZone = useDefaultStore((s) => {
    const chart = s.defaultCharts?.[idKey]
    if (chart && 'timeZone' in chart && chart.timeZone) {
      return chart.timeZone
    }
    return TimeZone.UTC
  })

  const updateChart = useDefaultStore((state) => state.updateTimeSeriesChart)
  const setExpandedChart = useDefaultStore((state) => state.setExpandedChart)
  const dispatch = useLiveStore((s) => s.actions.dispatch)
  const updateSeries = (s: ConstructKey) => {
    if (
      fetchListedExpiries &&
      'tenor' in s.middle &&
      'model' in s.middle &&
      s.middle.tenor.length > 4
    ) {
      const seriesAxis = getSeriesAxis({
        id: title,
        format: getFormat(
          `${s.suffix}${
            'series' in s.middle && s.middle.series ? `.${s.middle.series}` : ''
          }${
            'param' in s.middle && s.middle.param ? `.${s.middle.param}` : ''
          }`,
        ),
        updateAxis: (a: ChartState['axis']) => updateChart(idKey, 'axis', a),
        chartAxis,
        axisToAddTo: s.axis.yAxis,
      })
      if (seriesAxis === 'NONE') {
        return openToast([{ type: ToastType.AXIS }])
      }

      const listed = {
        isListedExpiry: true,
        source: s.source,
        model: s.middle.model,
        expiry: s.middle.tenor,
        color: s.color,
        baseAsset: s.middle.currency,
        suffix: defaultMiddle?.type
          ? (`${defaultMiddle.type[0]}.${s.suffix}` as 'vol.ratio')
          : s.suffix,
        field: 'series' in s.middle ? s.middle.series : field,
        axis: {
          yAxis: seriesAxis,
        },
      } as ListedSeries
      if (
        chartSeries.find(
          (s) =>
            'isListedExpiry' in s &&
            s.source === listed.source &&
            s.expiry === listed.expiry &&
            s.baseAsset === listed.baseAsset &&
            s.suffix === listed.suffix &&
            s.field === listed.field &&
            s.model === listed.model,
        )
      ) {
        return openToast([{ type: ToastType.EXISTS }])
      }

      // we want to return a ListedSeries item
      updateChart(idKey, 'series', [...chartSeries, listed])
    } else {
      const currentKeys = chartSeries.reduce((acc: string[], s) => {
        if (isConstructKey(s)) {
          const { assetClass, source, middle, suffix: suff } = s
          acc.push(
            `${constructKey({
              middle,
              assetClass,
              suffix: suff,
              source,
              frequency: '1h',
            })}${
              'series' in middle && middle.series ? `.${middle.series}` : ''
            }${'param' in middle && middle.param ? `.${middle.param}` : ''}`,
          )
        }
        return acc
      }, [])
      const newKey = `${constructKey({
        middle: s.middle,
        assetClass: s.assetClass,
        suffix: s.suffix,
        source: s.source,
        frequency: '1h',
      })}${
        'series' in s.middle && s.middle.series ? `.${s.middle.series}` : ''
      }${'param' in s.middle && s.middle.param ? `.${s.middle.param}` : ''}`

      if (currentKeys.includes(newKey)) {
        return openToast([{ type: ToastType.EXISTS }])
      }
      if (!newKey) return
      // const format = getModelParamLabelFormat(s.middle.type) // returns c,n, or p
      const seriesAxis = getSeriesAxis({
        id: title,
        format: getFormat(newKey),
        updateAxis: (a: ChartState['axis']) => updateChart(idKey, 'axis', a),
        chartAxis,
        axisToAddTo: s.axis.yAxis,
      })
      if (seriesAxis === 'NONE') {
        return openToast([{ type: ToastType.AXIS }])
      }
      if (seriesAxis) {
        updateChart(idKey, 'series', [
          ...chartSeries,
          {
            ...s,
            axis: {
              yAxis: seriesAxis,
            },
          },
        ])
      }
    }
  }

  const catalog = useContext(CatalogContext)
  const frequency = FREQUENCIES[chartTimeFreq] || FREQUENCIES['1h']

  const tenorKeys = useMemo(
    () =>
      chartSeries.reduce(
        (acc: { key: string; field: string; index: number }[], s, i) => {
          if (isConstructKey(s)) {
            const { assetClass, source, middle, suffix: suff } = s
            let key = constructKey({
              middle,
              assetClass,
              suffix: suff,
              source,
              frequency: frequency.unit === 'minutes' ? '1m' : '1h',
            })
            let f = getField(middle, field)
            if (key.endsWith('vol.atm')) {
              // TODO: Another HACK, format only works with bslang eval not getHistoric endpoint
              key = key.replace('vol.atm', 'smile')
              f = 'atm'
            }
            acc.push({
              key,
              field: f,
              index: i,
            })
          }
          return acc
        },
        [],
      ),
    [chartSeries, frequency.unit, field],
  )

  useEffect(() => {
    if (tenorKeys) {
      dispatch({
        type: LiveActionTypes.SUBSCRIBE,
        data: {
          keys: tenorKeys.reduce((acc: { qualified_name: string }[], k) => {
            const liveKey = getLiveKey(k.key, hasMinuteData)
            if (liveKey) {
              acc.push({
                qualified_name: liveKey,
              })
            }
            return acc
          }, []),
        },
      })
      return () => {
        const unsub = tenorKeys.reduce((acc: string[], k) => {
          const liveKey = getLiveKey(k.key, hasMinuteData)
          if (liveKey) {
            acc.push(liveKey)
          }
          return acc
        }, [])
        if (unsub.filter(Boolean).length) {
          dispatch({
            type: LiveActionTypes.UN_SUBSCRIBE,
            data: {
              streams: unsub.filter(Boolean),
            },
          })
        }
      }
    }
  }, [dispatch, tenorKeys, hasMinuteData])

  const liveData = useLiveStore(
    (s) =>
      tenorKeys.map(({ key }) =>
        getLiveKey(key, hasMinuteData)
          ? s.data[getLiveKey(key, hasMinuteData)]?.p
          : undefined,
      ),
    shallow,
  )

  const queries = tenorKeys.map(({ key, field: f }) => {
    return historicTimeseriesDataFetcher({
      key,
      field: f,
      refetchInterval: getRefetchInterval(
        'range' in chartTimings ? chartTimings?.range : undefined,
        hasMinuteData && frequency.unit === 'minutes',
      ),
      refetchOnWindowFocus:
        typeof chartTimings?.single === 'string' ||
        ('range' in chartTimings && typeof chartTimings?.range === 'string'),
    })
  })

  const dataFreq: DataFrequency = frequency.unit === 'minutes' ? '1m' : '1h'
  const listedSeries = chartSeries?.reduce<
    { series: ListedSeries; index: number }[]
  >((acc, s, i) => {
    if (!isConstructKey(s)) {
      const modifiedSeries = { ...s }
      if (modifiedSeries.suffix.endsWith('atm')) {
        // TODO: Another HACK - sorry future self, moving fast
        modifiedSeries.suffix = 'smile'
        modifiedSeries.field = 'atm'
      }
      acc.push({ series: modifiedSeries, index: i })
    }
    return acc
  }, [])

  const { listedExpiries, getActiveTimeSeries } = useDerivedActiveListedExpiry({
    queryKeys: listedSeries.map(
      ({ series }) =>
        `${series.source}.option.${series.baseAsset}${
          series.model ? `.${series.model}` : ''
        }.listed.${dataFreq}.${series.suffix}` as DerivedOptionQfn,
    ),
    refetchInterval: getRefetchInterval(
      'range' in chartTimings ? chartTimings?.range : undefined,
      hasMinuteData && frequency.unit === 'minutes',
    ),
    timestampRange: chartTimings,
    enabled: fetchListedExpiries,
    dataFreq,
    refetchOnWindowFocus:
      typeof chartTimings?.single === 'string' ||
      ('range' in chartTimings && typeof chartTimings?.range === 'string'),
  })
  const listedData = listedSeries?.map(({ series, index }) => ({
    ...getActiveTimeSeries(
      `${series.source}.option.${series.baseAsset}.${series.model}.listed.${series.expiry}.${dataFreq}.${series.suffix}`,
      series.field,
    ),
    index,
  }))

  const { data, isLoading } = useTimeSeriesQuery({
    queries,
    timestamp: getChartTimeStampRange(chartTimings, dataFreq),
    frequency: dataFreq,
  })

  const dataWithLive = useMemo(
    () =>
      'range' in chartTimings &&
      chartTimings?.range &&
      typeof chartTimings?.range === 'object'
        ? data?.map(
            (d, i) =>
              ({ ...d, index: i } as TimeSeriesData & { index: number }),
          )
        : data?.map((d, i) =>
            liveData[i]
              ? {
                  ...d,
                  dataPoints: [
                    ...d.dataPoints,
                    ...(liveData[i].pv &&
                    d.dataPoints?.length > 2 &&
                    differenceInSeconds(new Date(), liveData[i].t / 1000000) <
                      60
                      ? [
                          {
                            y: liveData[i].pv,
                            x:
                              Number(d.dataPoints[d.dataPoints.length - 1].x) +
                              (Number(d.dataPoints[d.dataPoints.length - 1].x) -
                                Number(
                                  d.dataPoints[d.dataPoints.length - 2].x,
                                )),
                          },
                          {
                            y: liveData[i].v,
                            x: getLiveTimestamp(
                              frequency,
                              chartTimeZone,
                              d.dataPoints,
                              liveData[i],
                            ),
                            liveTime: liveData[i].t,
                            increase: liveData[i].pv
                              ? liveData[i].v - liveData[i].pv
                              : 0,
                            live: true,
                          },
                        ]
                      : [
                          {
                            y: liveData[i]?.v,
                            x: getLiveTimestamp(
                              frequency,
                              chartTimeZone,
                              d.dataPoints,
                              liveData[i],
                            ),
                            liveTime: liveData[i].t,
                            live: true,
                          },
                        ]),
                  ],
                }
              : d,
          ),
    [liveData, chartTimeZone, data, frequency, chartTimings],
  )

  const ChartToRender =
    type === Chart.TIMESERIES
      ? MultiTimeSeriesLineChart
      : MultiTimeSeriesBarChart

  const recentDates = [
    ...(data?.map(
      (d) => new Date(d.dataPoints?.[d.dataPoints.length - 1]?.x || ''),
    ) || []),
    ...(liveData.map((d) => d?.t / 1000000) || []),
  ].filter((dte) => !Number.isNaN(new Date(dte).getTime()))

  const enlargedChart = useDefaultStore((state) => state.expandedId)
  const reindexedData = useMemo(() => {
    if (!listedData) return dataWithLive
    // As we have two sources of data rearrange the arrays so pills hover/colors match up easier
    const arr: TimeSeriesData[] = new Array(
      (listedData?.length || 0) + (dataWithLive?.length || 0),
    )
    listedData?.forEach((s) => (arr[s.index] = { ...s }))
    dataWithLive?.forEach((s, i) => (arr[tenorKeys[i].index] = { ...s }))
    return arr
  }, [dataWithLive, listedData, tenorKeys])
  return (
    <Modal
      open={enlargedChart === idKey}
      onClose={() => setExpandedChart(null)}
    >
      <ChartUIWrapper
        title={title}
        id={idKey}
        type={type}
        seriesPills={seriesPills}
        disableMinutes={disableMinutes}
        keys={tenorKeys}
        updatedStamp={new Date(max(recentDates || []))}
        timeZone={chartTimeZone}
        chartSeries={chartSeries}
        axis={axis}
        addSeries={
          <AddSeries
            currencies={currencies}
            sources={sources}
            assetType={assetType}
            suffix={suffix}
            defaultMiddle={defaultMiddle}
            listedExpiry={listedExpiry}
            updateSeries={updateSeries}
            useTypeToggle={useTypeToggle}
            series={series}
            useModelParam={useModelParam}
            addVol={addVol}
            color={{ index: chartSeries?.length || 0 }}
            useTenors={useTenors}
            tenors={tenors}
            availableTenors={availableTenors}
            expiries={listedExpiries}
            useFetchListed={fetchListedExpiries}
          />
        }
        chart={
          catalog.fetching || isLoading ? (
            <ChartLoader />
          ) : (
            <ChartToRender
              id={id}
              axis={chartAxis}
              chartSeries={chartSeries}
              data={reindexedData || []}
              frequency={frequency}
              valueAxisLabels={{
                format: axis?.LEFT,
                font: '0.7em "OpenSans"',
              }}
              timeZone={chartTimeZone}
              valueAxisTitle={axisLabel}
              valueAxisCrosshairFormat={axis?.LEFT}
              hoveredIndex={highlightedIndx}
              chartTimings={chartTimings}
            />
          )
        }
      />
      <ToastComponent />
    </Modal>
  )
}

export default MultiLineSeriesWrapper
