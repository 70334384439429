import {
  UseQueryResult,
  useQueries,
  useQueryClient,
} from '@tanstack/react-query'
import { isBefore, isAfter, isEqual } from 'date-fns'
import { useEffect } from 'react'
import { DataFrequency, getRefetchInterval, QueryObject } from 'services'
import { DateRange, TimeSeriesData } from 'types'

interface TimeSeriesQueryArgs<T> {
  queries: QueryObject[]
  timestamp: DateRange
  transformer?: (x: T) => T[]
  frequency?: DataFrequency
  enabled?: boolean
}

const isOnOrAfter = (date: Date | number, dateToCompareTo: Date) =>
  isAfter(date, dateToCompareTo) || isEqual(date, dateToCompareTo)

const isOnOrBefore = (date: Date | number, dateToCompareTo: Date) =>
  isBefore(date, dateToCompareTo) || isEqual(date, dateToCompareTo)

const equalDates = ({ cachedFrom, cachedTo, from, until }) =>
  isEqual(cachedFrom, from) && isEqual(cachedTo, until)
const timeRangeIsInsideCache = ({ cachedFrom, cachedTo, from, until }) =>
  isOnOrAfter(from, cachedFrom) && isOnOrBefore(until, cachedTo)

const alreadyHasNeededData = ({
  cachedFrom,
  cachedTo,
  from,
  until,
}: {
  cachedFrom: Date | number
  cachedTo: Date | number
  from: Date
  until: Date
}) => {
  if (!cachedFrom || !cachedTo) return false
  return (
    equalDates({
      cachedFrom,
      cachedTo,
      from,
      until,
    }) ||
    timeRangeIsInsideCache({
      cachedFrom,
      cachedTo,
      from,
      until,
    })
  )
}

/** TIMESERIES QUERY
 * This Hook is an implementation on top of react-queries hook.
 * WHY?
 * React-query has excellent caching logic, but it needs to be stored by keys.
 * therefore every time a user tries to load data for a smaller date range it refetches
 * under a new key, this stores the previous points in cache memory and then slices it accordingly
 *
 * Logic:
 * - If a date range is in the cache we return it
 * - Else we fetch the extra slither needed to merge the datapoints
 */
const useTimeSeriesQuery = <K = TimeSeriesData,>({
  queries,
  timestamp,
  transformer,
  frequency,
  enabled,
}: TimeSeriesQueryArgs<K>): {
  data: K[] | undefined
  isFetching: boolean
  isLoading: boolean
  errors: UseQueryResult[]
} => {
  const qc = useQueryClient()
  const queriesRes = useQueries({
    queries: queries.map(({ queryKey, fetcher }) => ({
      queryKey,
      queryFn: async ({ signal }: { signal?: AbortSignal }) => {
        const cached: TimeSeriesData | undefined =
          qc.getQueryData<TimeSeriesData>([...queryKey, 'totalPoints'])
        if (cached && cached?.dataPoints?.length) {
          let newCache = cached
          const cachedFrom = cached.dataPoints[0]?.x
          const cachedTo = cached.dataPoints[cached.dataPoints.length - 1]?.x
          if (
            !alreadyHasNeededData({
              cachedFrom,
              cachedTo,
              from: timestamp.from,
              until: timestamp.until,
            })
          ) {
            const fetchNewTimestampData =
              isAfter(timestamp.from, cachedFrom) ||
              isEqual(timestamp.from, cached.dataPoints[0].x)

            let newData = await fetcher(
              signal,
              fetchNewTimestampData // is timestamp after cache
                ? cached.dataPoints[cached.dataPoints.length - 1].x // new data at recent end of data
                : timestamp.from, // new  historical data
              fetchNewTimestampData ? timestamp.until : cached.dataPoints[0].x,
            )

            if (transformer) {
              newData = transformer(newData)
            }

            // Decide where to merge the two
            const total = {
              key: cached.key,
              dataPoints: !fetchNewTimestampData
                ? [...newData.dataPoints, ...cached.dataPoints]
                : [...cached.dataPoints.slice(0, -1), ...newData.dataPoints], // remove last as will be fetched again
              field: cached.field,
            }
            // set for future requests
            qc.setQueryData([...queryKey, 'totalPoints'], total)
            newCache = total
          }

          return {
            ...cached,
            dataPoints: newCache?.dataPoints.filter(
              (p) =>
                isOnOrAfter(p.x, timestamp.from) &&
                isOnOrBefore(p.x, timestamp.until),
            ),
          }
        }
        let res = await fetcher(signal, timestamp.from, timestamp.until)

        if (transformer) res = transformer(res)
        qc.setQueryData(
          [...queryKey, 'totalPoints'],
          Array.isArray(res) && res.length === 1 ? res[0] : res,
        )

        return { ...res, key: queryKey.filter(Boolean).join('.') }
      },
      cacheTime: 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      refetchInterval: getRefetchInterval('LATEST', frequency === '1m'),
      keepPreviousData: true,
      enabled,
    })),
  })

  useEffect(() => {
    if (queriesRes) queriesRes.map((q) => q.refetch())
  }, [
    timestamp?.from?.toISOString(),
    timestamp?.until?.toISOString(),
    queries.reduce((acc, { queryKey }) => `${acc},${queryKey.join('.')}`, ''),
  ])

  return {
    data: queriesRes.flatMap((q) => q.data || []),
    isFetching: !!queriesRes.find((q) => q.isFetching),
    isLoading: !!queriesRes.find((q) => q.isLoading),
    errors: queriesRes.filter((q) => q.status === 'error'),
  }
}

export default useTimeSeriesQuery
