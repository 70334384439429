import React from 'react'

import { Source, Ticker, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import { Model } from 'types/models'

import useMultiSeriesChart from 'hooks/useMultiSeriesChart'
import MultiLineSeriesWrapper from '../common/series/MultiSeriesWrapper'
import { SeriesPills } from '../common/series/Selector'
import useDerivedActiveListedExpiry from 'hooks/useDerivedActiveListedExpiry'
import { ConstructKey } from '../common/util'

const OptionVolatilityRatio: React.FC<{
  availableTenors?: `${number}d`[]
  defaultSeries?: Omit<ConstructKey, 'color' | 'axis'>[]
}> = ({ availableTenors, defaultSeries }) => {
  const title = 'Volatility (Implied, Realized & Ratio)'
  const id = `${AssetClass.OPTION}-${title}`
  const { errors } = useDerivedActiveListedExpiry({
    queryKeys: [`deribit.option.BTC.SVI.listed.1h.smile`],
    refetchInterval: Infinity,
    timestampRange: { range: '1d' }, // Short range just to get the possible expiries
    enabled: true,
    dataFreq: '1h',
    refetchOnWindowFocus: false,
  })

  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(
    id,
    defaultSeries ||
      ([
        {
          assetClass: AssetClass.OPTION,
          middle: {
            currency: Ticker.BTC,
            tenor: '',
            type: 'vol',
          },
          suffix: 'realized',
          source: Source.COMPOSITE,
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            model: Model.SABR,
            currency: Ticker.BTC,
            tenor: availableTenors?.[0] ?? '30d',
            type: 'vol',
          },
          suffix: 'ratio',
          source: Source.DERIBIT,
          axis: {
            yAxis: YAxis.RIGHT,
          },
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            model: Model.SABR,
            currency: Ticker.BTC,
            tenor: availableTenors?.[0] ?? '30d',
            type: 'vol',
          },
          suffix: 'atm',
          source: Source.DERIBIT,
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            currency: Ticker.ETH,
            tenor: '',
            type: 'vol',
          },
          suffix: 'realized',
          source: Source.COMPOSITE,
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            model: Model.SABR,
            currency: Ticker.ETH,
            tenor: availableTenors?.[0] ?? '30d',
            type: 'vol',
          },
          suffix: 'ratio',
          source: Source.DERIBIT,
          axis: {
            yAxis: YAxis.RIGHT,
          },
        },
        {
          assetClass: AssetClass.OPTION,
          middle: {
            model: Model.SABR,
            currency: Ticker.ETH,
            tenor: availableTenors?.[0] ?? '30d',
            type: 'vol',
          },
          suffix: 'atm',
          source: Source.DERIBIT,
        },
      ] as Omit<ConstructKey, 'color' | 'axis'>[]),
  )

  return (
    <MultiLineSeriesWrapper
      chartSeries={chartSeries}
      title={title}
      id={id}
      axisLabel="%"
      axis={{ LEFT: 'p2', RIGHT: 'p2' }}
      assetType={AssetClass.OPTION}
      suffix={['atm', 'ratio', 'realized']}
      useTenors
      availableTenors={availableTenors}
      fetchListedExpiries
      listedExpiry={{ enabled: errors.length === 0 }}
      defaultMiddle={{
        type: ['vol'],
      }}
      seriesPills={
        <SeriesPills
          chartSeries={chartSeries}
          id={id}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
    />
  )
}

export default OptionVolatilityRatio
