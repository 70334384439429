import { useQueries } from '@tanstack/react-query'
import { max } from 'date-fns'
import React, { useState } from 'react'

import Modal from 'components/atoms/Modal'
import Switch from 'components/atoms/Switch'
import ChartWrapper from 'components/common/ChartWrapper'
import { usePermissions } from 'hooks/usePermissions'
import useToasts from 'hooks/useToasts'
import { getOptionsSmile } from 'services/queries'
import { useDefaultStore } from 'stores'
import { SmileState } from 'stores/types'
import { Chart, Source, Ticker, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import { Model, SmileType } from 'types/models'

import ChartLoader from '../ChartLoader'
import AddSeries from '../common/series/AddSeries'
import { SmileSeriesPills } from '../common/series/Selector'
import { getDefaultSeries, getSeriesAxis } from '../common/series/utils'
import { ConstructKey, constructKey, SmileKey } from '../common/util'
import SmileChart from '../SmileChart'
import useDerivedActiveListedExpiry from 'hooks/useDerivedActiveListedExpiry'

const checkSameSeries = (s, arr: SmileKey[]) =>
  !!arr.find((a) => {
    if ('expiry' in a.middle) {
      return (
        a.middle.currency === s.middle.currency &&
        a.source === s.source &&
        a.timestamp === s.middle.timestamp &&
        a.middle.model === s.middle.model &&
        a.middle.expiry === s.middle.tenor
      )
    } else {
      return (
        a.middle.currency === s.middle.currency &&
        a.source === s.source &&
        a.timestamp === s.middle.timestamp &&
        a.middle.model === s.middle.model &&
        a.middle.tenor === s.middle.tenor
      )
    }
  })

const OptionsSmile: React.FC<{
  availableTenors?: string[]
  useLatestDatePicker?: boolean
}> = ({ availableTenors, useLatestDatePicker }) => {
  const [smileType, setSmileType] = useState<SmileType>(SmileType.SMILE)
  const [highlightedSeries, setHighlightedSeries] = useState<
    number | undefined
  >()
  const handleSmileChange = (checked: boolean) =>
    checked ? setSmileType(SmileType.MONEYNESS) : setSmileType(SmileType.SMILE)
  const { hasMinuteData } = usePermissions()
  const { openToast, ToastComponent, ToastType } = useToasts()
  const title = 'Volatility Smile'

  const {
    listedExpiries,
    isLoading: listedLoading,
    errors,
  } = useDerivedActiveListedExpiry({
    queryKeys: [`deribit.option.BTC.SVI.listed.1h.smile`],
    refetchInterval: Infinity,
    timestampRange: { range: '1d' }, // Short range just to get the possible expiries
    enabled: true,
    dataFreq: '1h',
    refetchOnWindowFocus: false,
  })

  const series = getDefaultSeries<Omit<SmileKey, 'color' | 'axis'>>([
    {
      assetClass: AssetClass.OPTION,
      middle: {
        currency: Ticker.BTC,
        model: Model.SVI,
        tenor: '30d',
      },
      suffix: smileType,
      source: Source.DERIBIT,
      timestamp: 'LATEST',
    },
    {
      assetClass: AssetClass.OPTION,
      middle: {
        currency: Ticker.BTC,
        model: Model.SVI,
        tenor: '90d',
      },
      suffix: smileType,
      source: Source.DERIBIT,
      timestamp: 'LATEST',
    },
    {
      assetClass: AssetClass.OPTION,
      middle: {
        currency: Ticker.BTC,
        model: Model.SVI,
        expiry: listedExpiries[2],
      },
      suffix: smileType,
      source: Source.DERIBIT,
      timestamp: 'LATEST',
    },
    {
      assetClass: AssetClass.OPTION,
      middle: {
        currency: Ticker.BTC,
        model: Model.SVI,
        expiry: listedExpiries[4],
      },
      suffix: smileType,
      source: Source.DERIBIT,
      timestamp: 'LATEST',
    },
  ]) as SmileKey[]
  const chart = useDefaultStore((state) => {
    const c = state.defaultCharts?.[title] as SmileState
    return c
  })
  const chartSeries = chart?.series || series
  const chartTz = chart?.timeZone

  const keys = chartSeries.map((series) => {
    const { assetClass, source, middle, timestamp } = series
    return {
      key: constructKey({
        middle,
        assetClass,
        suffix: smileType,
        source,
        frequency:
          hasMinuteData && timestamp && timestamp === 'LATEST' ? '1m' : '1h',
      }),
      timestamp: timestamp || 'LATEST',
    }
  })
  const queries = keys.map(({ key, timestamp }) =>
    getOptionsSmile({
      key,
      timestamp,
      smileType,
      hasMinuteData,
    }),
  )
  const results = useQueries({
    queries,
  })

  const isLoading = results.find((d) => d.isLoading)
  const mapping = chartSeries.reduce(
    (
      acc: {
        series: Record<
          string,
          {
            series: number[]
            categories: string[]
            timestamp: string
            color: string
            axis: YAxis
          }
        >
        dates: string[]
      },
      s,
      indx,
    ) => {
      if (results[indx].data?.timestamp) {
        const data = results[indx]?.data
        if (data && data.series[0] && data.timestamp) {
          if (typeof data.series[1][0] === 'number') {
            acc.series[`${data.series[0]}.${data.timestamp}`] = {
              series: data.series[1] as number[],
              categories: data.categories,
              timestamp: data?.timestamp,
              color: s.color,
              axis: s.axis.yAxis,
            }
          } else if ('expiry' in s.middle && Array.isArray(data.series[1][0])) {
            // listed res
            const exp = s.middle?.expiry
            const expiryISOIndx = data.categories.findIndex(
              (s) => s === 'expiry_iso',
            )
            const requiredExpiryDataIndx = (
              data.series[1][expiryISOIndx] as string[]
            ).findIndex((e) => exp === e)

            const seriesData = data.series[1].map(
              (d) => d[requiredExpiryDataIndx],
            )
            const series = [...seriesData]
            series.splice(expiryISOIndx, 1)
            const categories = data.categories.filter((s) => s !== 'expiry_iso')
            acc.series[`${data.series[0]}.${exp}`] = {
              series,
              categories,
              timestamp: data?.timestamp,
              color: s.color,
              axis: s.axis.yAxis,
            }
          }

          acc.dates.push(data.timestamp)
        }
      }
      return acc
    },
    { series: {}, dates: [] },
  )
  const updateChart = useDefaultStore((state) => state.updateSmile)

  const updateSeries = (s: ConstructKey) => {
    if (
      'currency' in s.middle &&
      'tenor' in s.middle &&
      'model' in s.middle &&
      'timestamp' in s.middle &&
      s.middle.timestamp
    ) {
      const existingSeries = checkSameSeries(s, chartSeries)
      if (existingSeries) {
        return openToast([{ type: ToastType.EXISTS }])
      }
      const seriesAxis = getSeriesAxis({
        id: title,
        format: 'p2',
        updateAxis: (a: SmileState['axis']) => updateChart(title, 'axis', a),
        chartAxis: chart.axis,
        axisToAddTo: s.axis.yAxis,
      })

      if (seriesAxis === 'NONE') {
        return openToast([{ type: ToastType.AXIS }])
      }
      const smileObj = {
        source: s.source,
        assetClass: AssetClass.OPTION,
        suffix: 'smile',
        middle: {
          currency: s.middle.currency,
          model: s.middle.model,
        },
        timestamp: s.middle.timestamp,
        axis: {
          yAxis: s.axis.yAxis,
        },
        color: s.color,
      } as SmileKey
      if (s.middle.tenor.length < 4) {
        smileObj.middle['tenor'] = s.middle.tenor
      } else {
        // listed
        smileObj.middle['expiry'] = s.middle.tenor
      }

      updateChart(title, 'series', [...chartSeries, smileObj])
    }
  }
  const enlargedChart = useDefaultStore((state) => state.expandedId)
  const setExpandedChart = useDefaultStore((state) => state.setExpandedChart)

  return listedLoading ? (
    <ChartLoader />
  ) : (
    <Modal
      open={enlargedChart === title}
      onClose={() => setExpandedChart(null)}
      square
    >
      <ChartWrapper
        id={title}
        chartSeries={chartSeries}
        updatedStamp={max(mapping.dates.map((d) => new Date(d)))}
        timeZone={chartTz}
        title={title}
        type={Chart.SMILE}
        axis={{ LEFT: 'p2' }}
        topBarContent={
          <Switch
            labelOne="Delta"
            labelTwo="Moneyness"
            onChange={handleSmileChange}
          />
        }
        addSeries={
          <AddSeries
            color={{ index: chartSeries?.length || 0 }}
            assetType={AssetClass.OPTION}
            updateSeries={updateSeries}
            useLatestDatePicker={useLatestDatePicker}
            useTenors
            availableTenors={availableTenors}
            useFetchListed
            expiries={listedExpiries}
            suffix={smileType}
            listedExpiry={{ enabled: errors.length === 0 }}
          />
        }
        seriesPills={
          <SmileSeriesPills
            chartSeries={chartSeries}
            dates={mapping.dates}
            id={title}
            setHighlightedSeries={setHighlightedSeries}
          />
        }
        chart={
          isLoading ? (
            <ChartLoader />
          ) : (
            <SmileChart
              data={mapping.series}
              title={smileType}
              axis={chart?.axis}
              highlightedSeries={highlightedSeries}
            />
          )
        }
      />
      <ToastComponent />
    </Modal>
  )
}

export default OptionsSmile
