import { DataFrequency } from 'services'
import AssetClass from './assetClass'
import { Model } from './models'

import { Source } from './source'
import { Ticker } from './ticker'

export type Instrument =
  | `${Ticker}-${string}`
  | `${Ticker}-${string}-${'C' | 'P'}`
export type DerivedOptionQfn =
  | `${Source}.option.${Ticker}.${Model}.listed.${DataFrequency}.${
      | 'params'
      | 'smile'
      | 'skew'
      | 'butterfly'
      | 'moneyness'}`
  | `${Source}.option.${Ticker}.${Model}.listed.${DataFrequency}${string}${
      | 'params'
      | 'smile'
      | 'skew'
      | 'butterfly'
      | 'moneyness'}`

export type QualifiedName = `${Source}.${AssetClass}.${Ticker | Instrument}.${
  | '1m'
  | '1h'
  | 'live'
  | 'tick'}.
  ${string}`

export enum QuoteAssetPair {
  USD = 'USD',
  USDC = 'USDC',
  USDT = 'USDT',
}
export type QuoteAsset = QuoteAssetPair | Ticker
