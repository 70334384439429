const TopBar = ({ children }) => (
  <div
    style={{
      padding: '0 2rem',
      minHeight: '16px',
    }}
  >
    {children}
  </div>
)

export default TopBar
