import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'
import { createColumnHelper, CellContext } from '@tanstack/react-table'

import Table from 'components/atoms/Table/Table'
import { H6 } from 'components/atoms/Typography/styles'
import CurrencyLogo from 'components/charts/common/CurrencyLogo'
import { CatalogContext } from 'context/CatalogContext'
import { useContext } from 'react'
import { useDefaultStore, useLiveStore } from 'stores'

import { QuoteAsset, QuoteAssetPair } from 'types'
import { DataValue, Movement, OptionRowData } from 'types/Monitors'
import './styles.scss'
import TopBar from './TopBar'
import { COLUMN_MAPPING, getLiveMovement, moneyFormat } from './utils'

const getCell = (
  info: CellContext<OptionRowData, string | number | DataValue | null>,
  type: 'C' | 'P',
) => {
  const original = info.cell.row.original[1]

  const movement = getLiveMovement(
    original?.[type]?.[info.column.id?.split('.')[1]]?.v,
    original?.[type]?.[info.column.id?.split('.')[1]]?.pv,
  )

  let className = 'neutral'
  if (movement === Movement.DOWN) {
    className = 'red'
  }
  if (movement === Movement.UP) {
    className = 'green'
  }
  if (/d\d+$/.test(info.column.id) || info.column.id.includes('iv')) {
    if (/d\d+$/.test(info.column.id) || /dytd$/.test(info.column.id)) {
      const val = info.getValue() || 0
      // Colour for 24hr change/ytd relates to overall change not previous val
      if (val > 0) {
        className = 'green'
      }
      if (val < 0) {
        className = 'red'
      }
      if (val === 0) {
        className = 'neutral'
      }
    }
    return info.getValue() ? (
      <div style={{ margin: 'auto', width: 'fit-content', textAlign: 'end' }}>
        <i className={className}>{Number(info.getValue()).toFixed(2)}%</i>
        {className === 'red' && <TriangleDownIcon className="red" />}
        {className === 'green' && <TriangleUpIcon className="green" />}
        {className === 'neutral' && (
          <TriangleUpIcon style={{ color: 'transparent' }} />
        )}
      </div>
    ) : (
      <i>-</i>
    )
  }

  let Component = <i className={className}>-</i>
  if (typeof info.getValue() === 'number' && !Number.isNaN(info.getValue())) {
    if (
      info.column.id.split('.')[1]?.startsWith('p') ||
      info.column.id.split('.')[1]?.includes('oi') ||
      info.column.id.split('.')[1]?.startsWith('iv')
    ) {
      Component = <>{info.getValue()}</>
    }
    if (info.column.id.split('.')[1]?.includes('v24')) {
      Component = <>{moneyFormat(info.getValue() as number)}</>
    }
    return (
      <div style={{ width: '100%', textAlign: 'end', paddingRight: '5%' }}>
        <i className={className}>{Component}</i>
        {className === 'red' && <TriangleDownIcon className="red" />}
        {className === 'green' && <TriangleUpIcon className="green" />}
        {className === 'neutral' && (
          <TriangleUpIcon style={{ color: 'transparent' }} />
        )}
      </div>
    )
  }
  return <i className={className}>{info.getValue() ?? '-'}</i>
}

const cols = ['p', 'pd24', 'iv', 'oi', 'v24']
const getColumns = (type: 'C' | 'P', quoteAsset: QuoteAsset) => {
  const arrs = type === 'C' ? [...cols].reverse() : cols
  return [
    ...arrs.map((k) => ({
      id: `${type}.${k}`,
      accessorFn: (row) => row[1][type]?.[k]?.v,
      header: () =>
        k === 'oi' || k === 'p' || k === 'v24' ? (
          <>
            {COLUMN_MAPPING[k]}{' '}
            <CurrencyLogo currency={quoteAsset} mr={0} height={12} width={12} />
          </>
        ) : (
          <>{COLUMN_MAPPING[k] || k}</>
        ),
      cell: (info) => getCell(info, type),
      enableGrouping: false,
      enableColumnFilter: k === 'oi' || k === 'v24',
      meta: { type },
    })),
  ]
}

const ListedExpiryMonitor = ({
  data = [],
  topBar,
  expiry,
}: {
  data: OptionRowData[]
  topBar: JSX.Element
  expiry: string
}) => {
  const catalog = useContext(CatalogContext)
  const exchange = useDefaultStore((s) => s.monitors.exchange)
  const ccy = useDefaultStore((s) => s.monitors.currency)
  const spot = useLiveStore(
    (s) => s.data[`${exchange.toLowerCase()}.${ccy}-${expiry}`]?.atm?.v,
  )
  const quoteAsset =
    catalog.state.activeOptions.find(
      (a) => a.exchange === exchange.toLowerCase() && a.baseAsset === ccy,
    )?.quoteAsset || QuoteAssetPair.USD
  const columnHelper = createColumnHelper<OptionRowData>()

  const columns =
    data.length > 0
      ? [
          columnHelper.group({
            header: 'Calls',
            id: 'Calls',
            enableColumnFilter: false,
            enableMultiSort: false,
            columns: getColumns('C', quoteAsset),
          }),
          columnHelper.group({
            id: 'ExpiryPicker',
            header: expiry,
            columns: [
              {
                id: 'strike',
                accessorFn: (r) => +r[0],
                cell: (info) => <b className="neutral">{info.getValue()}</b>,
              },
            ],
          }),
          columnHelper.group({
            header: 'Puts',
            id: 'Puts',
            enableColumnFilter: false,
            enableMultiSort: false,
            columns: getColumns('P', quoteAsset),
          }),
          // columnHelper.group({
          //   header: 'MID IV',
          //   id: 'IV',
          //   enableColumnFilter: false,
          //   enableMultiSort: false,
          //   columns: ['iv', 'iv30dmax', 'iv30dmin'].map((k) => ({
          //     id: k,
          //     accessorFn: (row) => row[1]?.IV?.[k]?.v,
          //     header: () => <>{COLUMN_MAPPING[k] || k}</>,
          //     cell: (info) => getCell(info, 'IV'),
          //     enableGrouping: false,
          //     enableColumnFilter: false,
          //     meta: { type: 'IV' },
          //   })),
          // }),
        ]
      : []

  return (
    <>
      <TopBar>{topBar}</TopBar>
      {data.length > 0 ? (
        <Table
          data={data}
          columns={columns}
          initialSortedColumn="strike"
          spot={spot}
          resetFiltersKey={ccy}
        />
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          No Data found.
        </div>
      )}
    </>
  )
}

export default ListedExpiryMonitor
