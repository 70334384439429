import 'react-grid-layout/css/styles.css'
import FuturesMonitor from 'components/live/Monitors/Futures'
import OptionsMonitor from 'components/live/Monitors/Options'
import ParamOptionsMonitor from 'components/live/Monitors/ParamsOptions'
import VolOptionsMonitor from 'components/live/Monitors/VolOptions'
import { Responsive, WidthProvider } from 'react-grid-layout'
import MonitorControls from 'components/live/Monitors/Controls'
import { H6 } from 'components/atoms/Typography/styles'
import LockedFeature from 'components/molecules/LockedFeature'

import { usePermissions } from 'hooks/usePermissions'

import ItemContainer from './ItemContainer'

const ResponsiveGridLayout = WidthProvider(Responsive)
const ParamTitle = () => (
  <H6 style={{ padding: '0.5rem' }}>Options Params (SABR)</H6>
)
const VolTitle = () => <H6 style={{ padding: '0.5rem' }}>Options Vol (SABR)</H6>
const MonitorDashboard = () => {
  const { hasMinuteData } = usePermissions()
  return (
    <div>
      <ResponsiveGridLayout
        layouts={{
          lg: [
            { i: 'controls', x: 0, y: 0, w: 16, h: 2, static: true },
            { i: 'top-left', x: 0, y: 3, w: 8, h: 9 },
            {
              i: 'top-right',
              x: 8,
              y: 3,
              w: 8,
              h: hasMinuteData ? 9 : 11,
              maxH: 15,
              minH: 5,
            },
            {
              i: 'bottom-left',
              x: 0,
              y: 13,
              w: 8,
              h: hasMinuteData ? 9 : 11,
              maxH: 16,
              minH: 5,
            },
            {
              i: 'bottom-right',
              x: 8,
              y: 13,
              w: 8,
              h: 12,
              maxH: 10,
              minH: 5,
            },
          ],
          md: [
            { i: 'controls', x: 0, y: 0, w: 10, h: 1.5, static: true },
            { i: 'top-left', x: 0, y: 2.5, w: 5, h: 7.5 },
            {
              i: 'top-right',
              x: 5,
              y: 2.5,
              w: 5,
              h: hasMinuteData ? 7.5 : 9.5,
              maxH: 28,
              minH: 6,
            },
            {
              i: 'bottom-left',
              x: 0,
              y: 12,
              w: 5,
              h: hasMinuteData ? 7.5 : 10,
              maxH: 20,
              minH: 6,
            },
            {
              i: 'bottom-right',
              x: 5,
              y: 14,
              w: 5,
              h: 9.5,
              maxH: 20,
              minH: 6,
            },
          ],
          sm: [
            { i: 'controls', x: 0, y: 0, w: 4, h: 1.5, static: true },
            { i: 'top-left', x: 0, y: 0, w: 2, h: 7.5 },
            { i: 'top-right', x: 2, y: 0, w: 2, h: 9, minH: 3 },
            {
              i: 'bottom-left',
              x: 0,
              y: 2,
              w: 2,
              h: hasMinuteData ? 8 : 12,
            },
            {
              i: 'bottom-right',
              x: 2,
              y: 2,
              w: 2,
              h: hasMinuteData ? 8 : 12,
            },
          ],
        }}
        breakpoints={{ lg: 2200, md: 1300, sm: 968, xs: 580, xxs: 0 }}
        rowHeight={30}
        cols={{ lg: 16, md: 10, sm: 4, xs: 1, xxs: 1 }}
        draggableHandle=".drag-handle"
      >
        <ItemContainer key="controls" padding={0} br={0}>
          <MonitorControls />
        </ItemContainer>
        <ItemContainer
          key={!hasMinuteData ? 'top-left' : 'bottom-left'}
          className="drag-handle"
          padding={0}
          overflow="hidden"
        >
          <FuturesMonitor />
        </ItemContainer>
        <ItemContainer
          key={!hasMinuteData ? 'top-right' : 'bottom-right'}
          className="drag-handle"
          padding={0}
          overflow="hidden"
        >
          <OptionsMonitor />
        </ItemContainer>
        <ItemContainer
          key={hasMinuteData ? 'top-left' : 'bottom-left'}
          className="drag-handle"
          padding={0}
          overflow="hidden"
        >
          {hasMinuteData ? (
            <ParamOptionsMonitor topBar={ParamTitle} />
          ) : (
            <>
              <ParamTitle />
              <LockedFeature />
            </>
          )}
        </ItemContainer>
        <ItemContainer
          key={hasMinuteData ? 'top-right' : 'bottom-right'}
          className="drag-handle"
          padding={0}
          overflow="hidden"
        >
          {hasMinuteData ? (
            <VolOptionsMonitor TopBar={VolTitle} />
          ) : (
            <>
              <VolTitle />
              <LockedFeature />
            </>
          )}
        </ItemContainer>
      </ResponsiveGridLayout>
    </div>
  )
}

export default MonitorDashboard
