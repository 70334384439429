import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'
import { createColumnHelper, CellContext } from '@tanstack/react-table'

import Table from 'components/atoms/Table/Table'
import CurrencyLogo from 'components/charts/common/CurrencyLogo'
import { CatalogContext } from 'context/CatalogContext'
import { memo, useContext, useMemo } from 'react'
import { useDefaultStore } from 'stores'
import { isLivePercentageVal } from 'stores/live'
import { LiveDataKey } from 'stores/types'
import { QuoteAsset, QuoteAssetPair } from 'types'

import { DataValue, Monitors, Movement, RowData } from 'types/Monitors'
import './styles.scss'
import TopBar from './TopBar'
import {
  COLUMN_MAPPING,
  expiryToDate,
  getLiveMovement,
  moneyFormat,
} from './utils'

const getCell = (
  info: CellContext<RowData, string | number | DataValue | null>,
) => {
  const original = info.cell.row.original[1]

  const movement = getLiveMovement(
    original?.[info.column.id]?.v,
    original?.[info.column.id]?.pv,
  )
  let className = 'neutral'
  if (movement === Movement.DOWN) {
    className = 'red'
  }
  if (movement === Movement.UP) {
    className = 'green'
  }
  if (
    /d\d+$/.test(info.column.id) ||
    /dytd$/.test(info.column.id) ||
    isLivePercentageVal(info.column.id as LiveDataKey)
  ) {
    if (/d\d+$/.test(info.column.id) || /dytd$/.test(info.column.id)) {
      // Colour for 24hr change/ytd relates to overall change not previous val
      if (original?.[info.column.id]?.v > 0) {
        className = 'green'
      }
      if (original?.[info.column.id]?.v < 0) {
        className = 'red'
      }
      if (original?.[info.column.id]?.v === 0) {
        className = 'neutral'
      }
    }
    return typeof info.getValue() === 'number' ? (
      <div style={{ margin: 'auto', width: 'fit-content', textAlign: 'end' }}>
        <i className={className}>{Number(info.getValue()).toFixed(2)}%</i>
        <span
          style={{
            display: 'inline-flex',
            alignContent: 'flex-end',
            position: 'relative',
            top: '4px',
          }}
        >
          {className === 'red' && <TriangleDownIcon className="red" />}
          {className === 'green' && <TriangleUpIcon className="green" />}
          {className === 'neutral' && (
            <TriangleUpIcon style={{ color: 'transparent' }} />
          )}
        </span>
      </div>
    ) : (
      <i>-</i>
    )
  }

  let Component = <i className="neutral">-</i>
  if (typeof info.getValue() === 'number' && !Number.isNaN(info.getValue())) {
    if (
      info.column.id.toLowerCase() === 'v24' ||
      info.column.id.toLowerCase() === 'oi'
    ) {
      Component = <>{moneyFormat(info.getValue() as number)}</>
    } else {
      Component = (
        <>
          {info
            ?.getValue()
            ?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            .replace('.00', '')}
        </>
      )
    }

    return (
      <div style={{ width: '100%', textAlign: 'end', paddingRight: '25%' }}>
        <i className={className}>{Component}</i>
        <span
          style={{
            display: 'inline-flex',
            alignContent: 'flex-end',
            position: 'relative',
            top: '4px',
          }}
        >
          {className === 'red' && <TriangleDownIcon className="red" />}
          {className === 'green' && <TriangleUpIcon className="green" />}
          {className === 'neutral' && (
            <TriangleUpIcon style={{ color: 'transparent' }} />
          )}
        </span>
      </div>
    )
  }
  return <i className={className}>{info.getValue() ?? '-'}</i>
}

const tenorPosition = {
  '1d': 0,
  '1W': 1,
  '2W': 2,
  '1M': 3,
  '3M': 4,
  '6M': 5,
  '1Y': 6,
}

const Header = memo(
  ({ c, quoteAsset }: { c: string; quoteAsset: QuoteAsset }) =>
    c === 'oi' || c === 'p' || c === 'v24' ? (
      <>
        {COLUMN_MAPPING[c] || c}{' '}
        <CurrencyLogo currency={quoteAsset} mr={0} height={14} width={14} />
      </>
    ) : (
      <>{COLUMN_MAPPING[c] || c}</>
    ),
)
Header.displayName = 'Header'

const Monitor = ({
  data = [],
  TopBarContent,
  monitor,
  cols = ['p', 'pd24', 'oi', 'v24'],
  idColLabel,
}: {
  data: RowData[]
  TopBarContent: () => JSX.Element
  monitor: Monitors
  cols?: (string | string[])[]
  idColLabel?: string
}) => {
  const catalog = useContext(CatalogContext)
  const exchange = useDefaultStore((s) => s.monitors.exchange)
  const ccy = useDefaultStore((s) => s.monitors.currency)
  const activeCatalog = monitor.includes('future')
    ? catalog.state.activeFutures
    : catalog.state.activeOptions

  const quoteAsset =
    activeCatalog.find(
      (a) => a.exchange === exchange.toLowerCase() && a.baseAsset === ccy,
    )?.quoteAsset || QuoteAssetPair.USD
  const columnHelper = createColumnHelper<RowData>()

  const columns = useMemo(
    () =>
      data.length > 0
        ? [
            columnHelper.accessor((r) => r[0], {
              id: idColLabel || 'contract',
              cell: (info) => <b className="neutral">{info.getValue()}</b>,
              enableGrouping: false,
              enableColumnFilter: false,
              sortingFn:
                idColLabel === 'tenor'
                  ? (rowA, rowB, id) =>
                      tenorPosition[rowA.getValue(id) as string] -
                      tenorPosition[rowB.getValue(id) as string]
                  : (rowA, rowB, id) =>
                      new Date(expiryToDate(rowA.getValue(id))).getTime() -
                      new Date(expiryToDate(rowB.getValue(id))).getTime(),
            }),
            ...cols.map((col) => {
              if (typeof col === 'object') {
                return columnHelper.group({
                  header: col[0],
                  id: `0.${col[0]}`,
                  enableColumnFilter: false,
                  enableMultiSort: false,
                  columns: col.slice(1).map((c) =>
                    columnHelper.accessor((row) => row[1][c]?.v, {
                      id: c,
                      cell: (info) => getCell(info),
                      enableGrouping: false,
                      header: () => <Header c={c} quoteAsset={quoteAsset} />,
                      enableColumnFilter: c === 'oi' || c === 'v24',
                      enableMultiSort: false,
                    }),
                  ),
                })
              }
              return columnHelper.accessor((row) => row[1][col]?.v, {
                id: col,
                cell: (info) => getCell(info),
                enableGrouping: false,
                header: () =>
                  col === 'oi' || col === 'p' || col === 'v24' ? (
                    <>
                      {COLUMN_MAPPING[col] || col}{' '}
                      <CurrencyLogo
                        currency={quoteAsset}
                        mr={0}
                        height={14}
                        width={14}
                      />
                    </>
                  ) : (
                    <>{COLUMN_MAPPING[col] || col}</>
                  ),
                enableColumnFilter: col === 'oi' || col === 'v24',
                enableMultiSort: false,
              })
            }),
          ]
        : [],
    [columnHelper, data, quoteAsset, cols],
  )

  return (
    <>
      <TopBar>
        <TopBarContent />
      </TopBar>
      {data.length > 0 ? (
        <Table
          data={data}
          columns={columns}
          resetFiltersKey={ccy}
          initialSortedColumn={idColLabel}
        />
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          No Data found.
        </div>
      )}
    </>
  )
}

export default Monitor
