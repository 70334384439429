import React, { useCallback } from 'react'

import { TooltipContext } from '@progress/kendo-react-charts'
import { formatNumber } from '@telerik/kendo-intl'
import ChartWrapper from 'components/common/ChartWrapper'
import {
  getCssColorValueForHeatmapCell,
  HeatMapLegend,
} from 'components/common/HeatMapLegend'
import InfoTooltip from 'components/molecules/InfoTooltip'
import { DaysTenor } from 'consts'
import { getOptionsHeatmapData, getRefetchInterval } from 'services'
import { AssetClassSource, Chart, HeatMapData, Source, Ticker } from 'types'
import useImportToAnalyzer from 'hooks/useImportToAnalyzer'
import AssetClass from 'types/assetClass'
import { Model } from 'types/models'
import { useQuery } from '@tanstack/react-query'
import { usePermissions } from 'hooks/usePermissions'

import ControlledSelect, {
  SelectItem,
  SelectItemIndicator,
} from 'components/atoms/Select/Index'
import { CheckIcon } from '@radix-ui/react-icons'
import { ItemText } from '@radix-ui/react-select'
import { useDefaultStore } from 'stores'
import { OptionHeatMap } from 'stores/types'
import { initialHeatmapChartState } from 'stores/slices/default-charts'
import HeatMapChart from '../HeatMapChart'
import ChartLoader, { ChartNoSeriesData } from '../ChartLoader'
import DateLatestToggle from '../common/DateLatestToggle'
import Modal from 'components/atoms/Modal'
import { useMediaQuery } from 'hooks/useMediaQuery'

const tooltipRenderer = (props: TooltipContext) => {
  if (!props.point) {
    return <></>
  }
  const { dataItem } = props.point
  return (
    <table>
      <tbody>
        <tr>
          <td>Tenor</td>
          <td>{DaysTenor[dataItem.expiry]}</td>
        </tr>
        <tr>
          <td>Value</td>
          <td>{formatNumber(dataItem.value, 'p2')}</td>
        </tr>
        <tr>
          <td>Strike</td>
          <td>{dataItem.strike.toUpperCase()}</td>
        </tr>
        <tr>
          <td>Z-Score</td>
          <td>{formatNumber(dataItem.zscore, 'n2')}</td>
        </tr>
      </tbody>
    </table>
  )
}
const CATEGORIES = {
  x: ['7', '30', '90', '120', '180', '270', '365', '730'],
  y: ['-10delta', '-25delta', 'atm', '25delta', '10delta'],
}

const OptionsHeatMap: React.FC = () => {
  const { hasMinuteData, validUser } = usePermissions()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const title = 'Implied Vol Surface'

  const currencies = [Ticker.BTC, Ticker.ETH]
  const sources = AssetClassSource[AssetClass.OPTION]
  const chart = useDefaultStore(
    (state) => state.defaultCharts?.[title] as OptionHeatMap,
  ) || { ...initialHeatmapChartState, model: Model.SABR }

  const updateChart = useDefaultStore((s) => s.updateHeatMap)
  const updateTiming = (single: 'LATEST' | Date) =>
    updateChart(title, 'timestamp', single)
  const updateHeatMap = (key, val) => updateChart(title, key, val)

  const importToAnalyzer = useImportToAnalyzer({ format: 'p2' })
  const { data, isLoading, isFetching } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'options',
      'heatmap',
      chart.source,
      chart.currency,
      chart.model || Model.SABR,
      chart.timestamp,
    ],
    queryFn: ({ signal }: { signal?: AbortSignal }) =>
      getOptionsHeatmapData(
        chart.source,
        chart.currency,
        chart.model || Model.SABR,
        hasMinuteData && chart.timestamp === 'LATEST' ? '1m' : '1h',
        chart.timestamp,
        signal,
      ),
    keepPreviousData: chart.timestamp !== 'LATEST',
    refetchInterval: getRefetchInterval(chart.timestamp, hasMinuteData),
  })
  const [series, timestamp] = data || []
  const handleCellClick = (item: HeatMapData) => {
    if (!item.timeseries?.series) {
      return
    }
    importToAnalyzer(
      [`${item.timeseries.series}.${item.timeseries.field}`],
      title,
    )
  }

  const zScores = series?.map((item) => item.zscore) || []
  const min = Math.min(...zScores)
  const max = Math.max(...zScores)
  const isOneMinData = hasMinuteData && chart.timestamp === 'LATEST'
  const colorCell = useCallback(
    (cell: HeatMapData) =>
      getCssColorValueForHeatmapCell(cell.zscore, min, max),
    [min, max],
  )
  const enlargedChart = useDefaultStore((state) => state.expandedId)
  const setExpandedChart = useDefaultStore((state) => state.setExpandedChart)
  const middle = Math.min(0, Number(((max + min) / 2).toPrecision(3)))
  return (
    <Modal
      open={enlargedChart === title}
      onClose={() => setExpandedChart(null)}
      square
    >
      <ChartWrapper
        id={title}
        type={Chart.HEATMAP}
        assetClass={AssetClass.OPTION}
        updatedStamp={timestamp ? new Date(timestamp) : undefined}
        timeZone={chart.timeZone}
        axis={{ LEFT: 'n2' }}
        legend={
          isLoading ? undefined : (
            <HeatMapLegend
              valueColorList={[
                {
                  value: min.toPrecision(3),
                  color: getCssColorValueForHeatmapCell(min, min, max),
                },
                {
                  value: middle,
                  color: getCssColorValueForHeatmapCell(middle, min, max),
                },
                {
                  value: max.toPrecision(3),
                  color: getCssColorValueForHeatmapCell(max, min, max),
                },
              ]}
              title="Z-Score"
            />
          )
        }
        title={title}
        topBarContent={
          <div style={{ display: 'flex' }}>
            <ControlledSelect
              value={chart.source}
              disabled={sources && sources.length <= 1}
              onValueChange={(v) => updateHeatMap('source', v)}
            >
              {sources.map((s) => (
                <SelectItem value={s} key={s}>
                  <SelectItemIndicator>
                    <CheckIcon />
                  </SelectItemIndicator>
                  <ItemText>{s}</ItemText>
                </SelectItem>
              ))}
            </ControlledSelect>
            <ControlledSelect
              defaultValue={chart.currency}
              onValueChange={(v) => updateHeatMap('currency', v)}
            >
              {(currencies || []).map((s) => (
                <SelectItem value={s} key={s}>
                  <SelectItemIndicator>
                    <CheckIcon />
                  </SelectItemIndicator>
                  <ItemText>{s}</ItemText>
                </SelectItem>
              ))}
            </ControlledSelect>
            <ControlledSelect
              defaultValue={chart.model || Model.SABR}
              onValueChange={(v) => updateHeatMap('model', v)}
            >
              {[Model.SABR, Model.SVI].map((s) => (
                <SelectItem value={s} key={s}>
                  <SelectItemIndicator>
                    <CheckIcon />
                  </SelectItemIndicator>
                  <ItemText>{s}</ItemText>
                </SelectItem>
              ))}
            </ControlledSelect>
            {!isMobile && (
              <DateLatestToggle setDate={updateTiming} date={chart.timestamp} />
            )}
          </div>
        }
        infoButton={
          <InfoTooltip
            title={
              !validUser
                ? `Sign up to explore cell data in our Historical Analyzer tool`
                : `Click on a cell to explore the underlying timeseries in our Analyzer ${
                    isOneMinData ? '(1min data is currently unavailable)' : ''
                  }.`
            }
          />
        }
        chart={
          <>
            {!isLoading && !isFetching && !series && <ChartNoSeriesData />}
            {isLoading && <ChartLoader />}
            {series && (
              <HeatMapChart
                data={series}
                color={colorCell}
                categories={CATEGORIES}
                tooltipRenderer={tooltipRenderer}
                xAxisTitle="Tenor"
                yAxisTitle="Delta"
                onCellClick={handleCellClick}
                isOneMinData={isOneMinData}
              />
            )}
          </>
        }
      />
    </Modal>
  )
}

export default OptionsHeatMap
