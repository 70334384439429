import { Toast } from 'components/molecules/Toasts'
import { useCallback, useState } from 'react'

enum ToastType {
  'EXISTS',
  'AXIS',
  'ERROR',
}
const useToasts = () => {
  const [toasts, setToast] = useState<
    {
      type?: ToastType
      description?: string
      title?: string
    }[]
  >([])

  const deleteToast = (indx) =>
    setToast((p) => {
      const n = [...p]
      n.splice(indx, 1)
      return n
    })
  const ToastComponent = () => (
    <>
      {toasts.map((toast, indx) => {
        if (toast.type === ToastType.AXIS) {
          return (
            <Toast
              key={indx}
              description={
                toast?.description ||
                'We are unable to add this series to the specified axis, this maybe due to the existing axis having a different format.'
              }
              title={toast?.title || 'Unable to add series'}
              setOpen={() => deleteToast(indx)}
            />
          )
        }

        if (toast.type === ToastType.ERROR) {
          // eslint-disable-next-line react/display-name
          return (
            <Toast
              isError
              description={
                toast?.description ||
                'An error occured, please try again or contact support.'
              }
              title={toast?.title || 'An Unexpected Error Occurred.'}
              setOpen={() => deleteToast(indx)}
            />
          )
        }
        return (
          <Toast
            key={indx}
            description={
              toast?.description ||
              'This series is already present, try selecting another series or click on the series pill to edit.'
            }
            title={toast?.title || 'Series already selected'}
            setOpen={() => deleteToast(indx)}
          />
        )
      })}
    </>
  )

  const openToast = useCallback(
    (
      messages: {
        type: ToastType
        description?: string
        title?: string
      }[],
    ) => {
      const unique = messages.filter(
        (m) =>
          !toasts.find(
            (toast) =>
              toast.description === m.description && toast.title === m.title,
          ),
      )
      setToast((p) => [...p, ...unique])
    },
    [toasts],
  )
  return { openToast, ToastComponent, ToastType }
}

export default useToasts
