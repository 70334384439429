import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridItemChangeEvent,
  GridNoRecords,
  GridToolbar,
} from '@progress/kendo-react-grid'
import { useQueries, useQuery } from '@tanstack/react-query'
import Button from 'components/atoms/Button'
import Page from 'components/atoms/Page'
import { H3 } from 'components/atoms/Typography/styles'
import CurrencyLogo from 'components/charts/common/CurrencyLogo'
import DataChooser from 'components/common/DataChooser'
import { useLayoutEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { OptionType, Source, Ticker } from 'types'
import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'
import { useDebounce } from 'hooks/useDebounce'
import { usePermissions } from 'hooks/usePermissions'
import { getOptionPrice, getSpotPrice } from 'services/queries'
import { useDefaultStore } from 'stores'
import { palette } from 'styles/theme'
import { EditableOptionPriceQuery, PricerDataWithResults } from 'types/queries'

import { DATE_FORMAT, NOW_UTC, getXDaysAhead } from 'consts'
import { add, format } from 'date-fns'
import { Model } from 'types/models'
import GetScenarioAnalysis from './Analysis/GetScenarioAnalysis'
import ScenarioAnalysis from './Analysis/ScenarioAnalysis'
import {
  CustomInputCell,
  DateDropdownCell,
  OptionPricerCommandCell,
  RadioAndDropDownCell,
  RadioOrDropDownCell,
  StyledDatePicker,
} from './cells'
import {
  MODEL_OPTIONS,
  PURCHASE_OPTIONS,
  TICKER_OPTIONS,
  TYPE_OPTIONS_PRO,
  TYPE_OPTIONS_STANDARD,
} from './consts'
import { OptionPriceQueryNumberKey } from './types'
import { queryIsEnriched } from './utils'
import { useMediaQuery } from 'hooks/useMediaQuery'
import Modal from 'components/atoms/Modal'
import { Dropdown } from 'components/atoms'
import { ItemText } from '@radix-ui/react-select'

import ControlledSelect, {
  SelectItem,
  SelectItemIndicator,
} from 'components/atoms/Select/Index'
import { CheckIcon } from '@radix-ui/react-icons'
import { Field, Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import ErrorMessage from 'components/atoms/Typography/ErrorMessage'

const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;

  .k-toolbar {
    border-style: none;
    padding: 0 0 12px 0px;
    background-color: inherit;
  }

  .k-grid table {
    border: 1px solid ${palette.common.borderColorMain} !important;
    width: 99% !important;

    button {
      background-color: ${({ theme }) => theme.palette.common.blue4};
      width: 24px;
      height: 24px;
      :hover {
        background-color: ${({ theme }) => theme.palette.common.blue6};
      }
    }
  }
  .k-grid {
    border: none;
    width: 100% !important;
  }
  .k-toolbar .k-input,
  .k-toolbar .k-picker {
    width: 90px;
    border-bottom: 1px solid ${palette.common.white} !important;
  }
  .k-mr-4 {
    margin-right: 0 !important;
  }
  .k-mr-2 {
    margin-right: 0 !important;
    padding-left: 0.2rem;
  }
`

// TODO: all this was done in a rush, needs redone to avoid abusing !important
const StyledGrid = styled(Grid)`
  background-color: inherit;
  & .k-input-inner,
  & .k-button-sm,
  & input.k-input {
    padding: 0.3rem !important;
    border: none;
    color: white !important;
  }
  &:: focus {
    border: 1px solid white;
  }
  &:: focus {
    border: 1px solid white;
  }
  & .k-input-md,
  & .k-buttom-sm,
  & input.k-input {
    font-size: clamp(0.5rem, 0.7rem, 1rem);
    color: white !important;
    border-radius: 0.25rem;
  }
  & input.k-input:focus {
    border: 1px solid ${palette.common.white} !important;
  }
  & .k-picker {
    border: none !important;
    background-color: inherit;
  }
  & .k-icon {
    width: 0.6rem !important;
    height: 0.6rem !important;
  }
  & {
  }
  & tr[aria-rowindex='1'] .k-header[aria-colindex='1'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='1'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='2'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='3'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='4'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='5'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='6'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='7'] {
    background: ${({ theme }) => theme.palette.background.base} !important;
  }
  & tr[aria-rowindex='1'] .k-header[aria-colindex='8'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='8'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='9'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='10'] {
    background: ${({ theme }) => theme.palette.common.blue1} !important;
  }
  & tr[aria-rowindex='2'] .k-header[aria-colindex='11'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='12'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='13'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='14'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='15'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='16'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='17'] {
    background: ${({ theme }) => theme.palette.common.blue3} !important;
  }
  & td[aria-colindex='4'] .k-input-spinner {
    display: none;
  }
  .k-grid-edit-cell .k-input {
    border: 1px solid #44494f !important;
  }
`

const summedFields: Array<OptionPriceQueryNumberKey> = [
  'price',
  'delta',
  'gamma',
  'vega',
  'theta',
  'volga',
  'vanna',
  'quantity',
]

type SummationTotals = Record<Ticker, Record<OptionPriceQueryNumberKey, number>>
interface IBasePricer {
  source: Source
  setSource: (s: Source) => void
  isLoading: boolean
  data: PricerDataWithResults[]
  fieldSums: SummationTotals
}
const ccyEnum = TICKER_OPTIONS.map((t) => t.value)
const MobileSchema = z.object({
  ccy: z.enum([...(ccyEnum as [string, ...string[]])]),
  strike: z.number().positive(),
  type: z.string(),
  model: z.enum([Model.SABR, Model.SVI]),
  expiry: z.date(),
  quantity: z.number(),
})

const ListItem = ({ children }) => (
  <li style={{ display: 'flex', flexDirection: 'column', justifySelf: 'left' }}>
    {children}
  </li>
)
const MobileModal = ({
  isOpen,
  setOpen,
  optionTypeData,
  addOption,
  source,
}) => {
  const theme = useTheme()
  const { maxDate } = usePermissions()
  const min = getXDaysAhead(1)

  return (
    <Modal open={isOpen} isOnlyModal onClose={() => setOpen(false)} isMobile>
      <H3
        style={{
          alignSelf: 'flex-start',
          margin: 'auto',
          padding: '0.5rem 0rem 1rem',
        }}
      >
        Price an Option
      </H3>
      <Formik
        initialValues={{
          ccy: TICKER_OPTIONS[0].label,
          model: Model.SABR,
          strike: 0,
          type: optionTypeData[0].label,
          expiry: min,
          quantity: 1,
          source,
          pricingTimestamp: 'LATEST',
        }}
        validationSchema={toFormikValidationSchema(MobileSchema)}
        onSubmit={(values) => {
          addOption({ ...values, expiry: values.expiry })
          setOpen(false)
        }}
      >
        {({ setFieldValue, values, isSubmitting, errors, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <ol
                style={{
                  gridTemplateColumns: 'repeat(auto-fill, 120px)',
                  display: 'grid',
                  textAlign: 'left',
                  gridGap: '0 20px',
                  padding: 0,
                }}
              >
                <ListItem>
                  Currency
                  <ControlledSelect
                    value={values.ccy}
                    onValueChange={(v) => setFieldValue('currency', v)}
                  >
                    {TICKER_OPTIONS.map((s) => (
                      <SelectItem value={s.value} key={s.value}>
                        <SelectItemIndicator>
                          <CheckIcon />
                        </SelectItemIndicator>
                        <ItemText>{s.label}</ItemText>
                      </SelectItem>
                    ))}
                  </ControlledSelect>
                </ListItem>
                <ListItem>
                  Model
                  <ControlledSelect
                    value={values.model}
                    onValueChange={(v) => setFieldValue('model', v)}
                  >
                    {MODEL_OPTIONS.map((s) => (
                      <SelectItem value={s.value} key={s.value}>
                        <SelectItemIndicator>
                          <CheckIcon />
                        </SelectItemIndicator>
                        <ItemText>{s.label}</ItemText>
                      </SelectItem>
                    ))}
                  </ControlledSelect>
                </ListItem>
                <ListItem>
                  Type
                  <ControlledSelect
                    value={values.type}
                    onValueChange={(v) => setFieldValue('type', v)}
                  >
                    {optionTypeData.map((s) => (
                      <SelectItem value={s.label} key={s.value}>
                        <SelectItemIndicator>
                          <CheckIcon />
                        </SelectItemIndicator>
                        <ItemText>{s.label}</ItemText>
                      </SelectItem>
                    ))}
                  </ControlledSelect>
                </ListItem>
                <ListItem>
                  Strike ($)
                  <Field
                    type="number"
                    name="strike"
                    min="0"
                    style={{
                      border: 0,
                      backgroundColor: theme.palette.common.blue12,
                      width: '65px',
                      borderRadius: '4px',
                    }}
                  />
                  {errors?.strike && (
                    <ErrorMessage>{errors.strike}</ErrorMessage>
                  )}
                </ListItem>
                <ListItem>
                  Expiry:{' '}
                  <Field
                    type="date"
                    id="expiry"
                    onChange={(e: DatePickerChangeEvent) => {
                      if (e.target.value) {
                        setFieldValue('expiry', new Date(e.target.value))
                      }
                    }}
                    value={format(new Date(values.expiry), 'yyyy-MM-dd')}
                    format={DATE_FORMAT}
                    min={format(min, 'yyyy-MM-dd')}
                    max={format(maxDate, 'yyyy-MM-dd')}
                  />
                  {errors?.expiry && (
                    <ErrorMessage>{errors.expiry}</ErrorMessage>
                  )}
                </ListItem>
                <ListItem>
                  Quantity
                  <Field
                    type="number"
                    name="quantity"
                    style={{
                      border: 0,
                      backgroundColor: theme.palette.common.blue12,
                      width: '65px',
                      borderRadius: '4px',
                    }}
                  />
                  {errors?.quantity && (
                    <ErrorMessage>{errors.quantity}</ErrorMessage>
                  )}
                </ListItem>
              </ol>
              <Button
                type="submit"
                variant="contained"
                style={{ width: '80%', margin: 'auto' }}
              >
                {isSubmitting ? 'Adding...' : 'Add'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
const useOptionPricerDefaults = (useDefaults?: boolean) => {
  const setDefaults = useDefaultStore((state) => state.options.setDefaults)
  const { data: BTCSpot, isLoading: btcLoading } = useQuery({
    ...getSpotPrice({ exchange: Source.DERIBIT, currency: Ticker.BTC }),
    enabled: !!useDefaults,
  })
  const { data: ETHSpot, isLoading: ethLoading } = useQuery({
    ...getSpotPrice({ exchange: Source.DERIBIT, currency: Ticker.ETH }),
    enabled: !!useDefaults,
  })

  useLayoutEffect(() => {
    if (ETHSpot && BTCSpot) {
      setDefaults([
        {
          id: uuidv4(),
          inEdit: true,
          quantity: 10,
          pricingTimestamp: 'LATEST',
          ccy: Ticker.BTC,
          model: Model.SVI,
          type: OptionType.CALL,
          expiry: add(NOW_UTC(false), { days: 14 }),
          strike: Math.round((BTCSpot * 1.15) / 50) * 50,
        },
        {
          id: uuidv4(),
          inEdit: true,
          quantity: -10,
          pricingTimestamp: 'LATEST',
          ccy: Ticker.ETH,
          model: Model.SABR,
          type: OptionType.PUT,
          expiry: add(NOW_UTC(false), { days: 14 }),
          strike: Math.round((ETHSpot * 1.05) / 50) * 50,
        },
      ])
    }
  }, [ETHSpot, BTCSpot])
}

const BasePricer = ({
  data,
  source,
  setSource,
  fieldSums,
  isLoading,
}: IBasePricer) => {
  const { hasMinuteData, validUser } = usePermissions()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [mobileModalOpen, setMobileModelOpen] = useState(false)
  const addOption = useDefaultStore((state) => state.options.addOption)
  const openMobileModal = () => setMobileModelOpen(true)
  const updateOption = useDefaultStore((state) => state.options.updateOption)
  const queries = useDefaultStore((state) => state.options.queries)

  const update = (dataItem: EditableOptionPriceQuery) => {
    const index = queries.findIndex((pq) => pq.id === dataItem.id)
    updateOption(dataItem, index)
  }

  const resetOptionalParams = (dataItem: EditableOptionPriceQuery) => {
    const index = queries.findIndex((pq) => pq.id === dataItem.id)
    updateOption(
      { ...queries[index], userIv: undefined, userSpot: undefined },
      index,
    )
  }

  const debouncedItemHandler = useDebounce({
    fn: (event: GridItemChangeEvent) => {
      const index = queries.findIndex((pq) => pq.id === event.dataItem.id)
      updateOption(
        { ...queries[index], [event.field as string]: event.value },
        index,
      )
    },
    time: 500,
  })

  const CommandCell = (cellProps: GridCellProps & { isMobile?: boolean }) => {
    return (
      <OptionPricerCommandCell
        {...cellProps}
        reset={resetOptionalParams}
        isLoading={isLoading}
        isMobile={isMobile}
      />
    )
  }

  const optionTypeData =
    hasMinuteData && validUser
      ? [...TYPE_OPTIONS_STANDARD, ...TYPE_OPTIONS_PRO]
      : TYPE_OPTIONS_STANDARD
  const showFooterRow = data?.some(queryIsEnriched)

  return (
    <>
      {isMobile && (
        <MobileModal
          isOpen={mobileModalOpen}
          setOpen={setMobileModelOpen}
          optionTypeData={optionTypeData}
          addOption={addOption}
          source={source}
        />
      )}
      <ColumnLayout>
        <StyledGrid
          style={{
            fontSize: isMobile ? '0.5rem' : '0.9rem',
          }}
          data={data}
          onItemChange={debouncedItemHandler}
          editField="inEdit"
          scrollable="none"
        >
          <GridToolbar>
            <DataChooser
              source={source}
              supportedSources={[Source.DERIBIT]}
              handleSourceChange={setSource}
            />
            <H3
              style={{
                alignSelf: 'flex-start',
                margin: 'auto',
                padding: '2rem 0rem 1rem',
              }}
            >
              Options Pricer
            </H3>
            <Button
              size="sm"
              type="button"
              onClick={() => (isMobile ? openMobileModal() : addOption())}
              style={{
                alignSelf: 'flex-end',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Add new{' '}
                <span
                  className="k-icon k-i-plus"
                  style={{ marginLeft: '0.5rem' }}
                />
              </span>
            </Button>
          </GridToolbar>
          {isMobile ? (
            <Column
              cell={CommandCell}
              footerCell={
                showFooterRow
                  ? () => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                          }}
                        >
                          <span>Totals</span>
                          {Object.entries(fieldSums).map(([c]) => (
                            <span key={c}>
                              <CurrencyLogo currency={c as Ticker} /> {c}
                            </span>
                          ))}
                        </div>
                      </td>
                    )
                  : undefined
              }
            />
          ) : (
            <Column title="Input">
              <Column
                field="model"
                title="Model"
                cell={(cellProps) => (
                  <RadioOrDropDownCell {...cellProps} data={MODEL_OPTIONS} />
                )}
                width="110px"
              />
              <Column
                field="type"
                title="Type"
                cell={(cellProps) => (
                  <RadioOrDropDownCell {...cellProps} data={optionTypeData} />
                )}
                width="100px"
              />
              <Column
                field="ccy"
                title="Ccy"
                cell={(cellProps) => (
                  <RadioOrDropDownCell {...cellProps} data={TICKER_OPTIONS} />
                )}
                width="105px"
              />
              <Column
                field="strike"
                title="Strike ($)"
                editor="numeric"
                width="90px"
              />
              <Column
                field="pricingTimestamp"
                title="Value Date"
                cell={(cellProps, i) => (
                  <RadioAndDropDownCell
                    {...cellProps}
                    data={PURCHASE_OPTIONS}
                  />
                )}
                width="120px"
              />
              <Column
                field="expiry"
                title="Expiry"
                cell={DateDropdownCell}
                width="100px"
              />
              <Column
                cell={CommandCell}
                width="100px"
                footerCell={
                  showFooterRow
                    ? () => (
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                            }}
                          >
                            <span>Totals</span>
                            {Object.entries(fieldSums).map(([c]) => (
                              <span key={c}>
                                <CurrencyLogo currency={c as Ticker} /> {c}
                              </span>
                            ))}
                          </div>
                        </td>
                      )
                    : undefined
                }
              />
            </Column>
          )}
          {!isMobile && (
            <Column title="Optional">
              <Column
                field="quantity"
                title="Quantity"
                width="40px"
                cell={(cellProps) => {
                  return (
                    <CustomInputCell
                      {...cellProps}
                      update={(value) => {
                        update({ ...cellProps.dataItem, quantity: value })
                      }}
                    />
                  )
                }}
                footerCell={
                  showFooterRow
                    ? () => (
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {Object.entries(fieldSums).map(([cur, obj]) => (
                              <span key={cur}>{obj.quantity}</span>
                            ))}
                          </div>
                        </td>
                      )
                    : undefined
                }
              />
              <Column
                field="implied vol"
                title="Implied Vol"
                width="80px"
                cell={(cellProps) => {
                  return (
                    <CustomInputCell
                      {...cellProps}
                      update={(value) => {
                        update({ ...cellProps.dataItem, userIv: value })
                      }}
                    />
                  )
                }}
                footerCell={showFooterRow ? () => <td>-</td> : undefined}
              />
              <Column
                field="spot"
                title="Spot"
                width="80px"
                cell={(cellProps) => {
                  return (
                    <CustomInputCell
                      {...cellProps}
                      update={(value) => {
                        update({ ...cellProps.dataItem, userSpot: value })
                      }}
                    />
                  )
                }}
                footerCell={showFooterRow ? () => <td>-</td> : undefined}
              />
            </Column>
          )}

          <Column title="Output">
            <Column
              field="price"
              title="Price"
              format="{0:n2}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {Object.entries(fieldSums).map(([cur, obj]) => (
                            <span key={cur}>{obj.price.toPrecision(3)}</span>
                          ))}
                        </div>
                      </td>
                    )
                  : undefined
              }
            />
            <Column
              field="delta"
              title="Delta"
              format="{0:n2}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {Object.entries(fieldSums).map(([cur, obj]) => (
                            <span key={cur}>{obj.delta.toPrecision(3)}</span>
                          ))}
                        </div>
                      </td>
                    )
                  : undefined
              }
            />
            <Column
              field="gamma"
              title="Gamma"
              format="{0:e3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {Object.entries(fieldSums).map(([cur, obj]) => (
                            <span key={cur}>{obj.gamma.toPrecision(3)}</span>
                          ))}
                        </div>
                      </td>
                    )
                  : undefined
              }
            />
            <Column
              field="vega"
              title="Vega"
              format="{0:n3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {Object.entries(fieldSums).map(([cur, obj]) => (
                            <span key={cur}>{obj.vega.toPrecision(3)}</span>
                          ))}
                        </div>
                      </td>
                    )
                  : undefined
              }
            />
            <Column
              field="theta"
              title="Theta"
              format="{0:n3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {Object.entries(fieldSums).map(([cur, obj]) => (
                            <span key={cur}>{obj.theta.toPrecision(3)}</span>
                          ))}
                        </div>
                      </td>
                    )
                  : undefined
              }
            />
            <Column
              field="volga"
              title="Volga"
              format="{0:n3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {Object.entries(fieldSums).map(([cur, obj]) => (
                            <span key={cur}>{obj.volga.toPrecision(3)}</span>
                          ))}
                        </div>
                      </td>
                    )
                  : undefined
              }
            />
            <Column
              field="vanna"
              title="Vanna"
              format="{0:n3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <span />
                          {Object.entries(fieldSums).map(([cur, obj]) => (
                            <span key={cur}>{obj.vanna.toPrecision(3)}</span>
                          ))}
                        </div>
                      </td>
                    )
                  : undefined
              }
            />
          </Column>
          <GridNoRecords>
            Click &apos;add new&apos; to create a new price query
          </GridNoRecords>
        </StyledGrid>
      </ColumnLayout>
    </>
  )
}

const ScenarioAnalysisWrapper = ({
  data,
  fieldSums,
  source,
}: {
  source: Source
  data: PricerDataWithResults[]
  fieldSums: SummationTotals
}) => {
  const { hasMinuteData } = usePermissions()

  return hasMinuteData ? (
    <ScenarioAnalysis
      optionData={data}
      fieldSums={fieldSums}
      exchange={source}
    />
  ) : (
    <GetScenarioAnalysis />
  )
}

const OptionPricer: React.FC<{
  useScenarioAnalysis: boolean
  useDefaults?: boolean
}> = ({ useScenarioAnalysis = false, useDefaults }) => {
  useOptionPricerDefaults(useDefaults)
  const { hasMinuteData } = usePermissions()
  const queries = useDefaultStore((state) => state.options.queries)

  const [source, setSource] = useState(Source.DERIBIT)
  const results = useQueries({
    queries: queries.map((query) =>
      getOptionPrice(
        query,
        source,
        query.pricingTimestamp === 'LATEST' && hasMinuteData ? '1m' : '1h',
      ),
    ),
  })

  const dataWithResults = useMemo(
    () =>
      queries.map((pq, i) => {
        return {
          ...pq,
          ...results[i].data?.data,
          isLoading: results[i].isLoading,
          isFetching: results[i].isFetching,
        } as PricerDataWithResults
      }),
    [queries, results],
  )
  const isLoading = results.some((result) => result.isLoading)

  const fieldSums = dataWithResults.reduce((acc, cur) => {
    if (cur.ccy) {
      if (!acc[cur.ccy]) {
        return {
          ...acc,
          [cur.ccy]: summedFields.reduce(
            (a, f) => ({
              ...a,
              [f]: !cur[f] ? 0 : (cur[f] as number),
            }),
            {},
          ),
        }
      }
      return {
        ...acc,
        [cur.ccy]: summedFields.reduce(
          (a, f) => ({
            ...a,
            [f]:
              !cur[f] && !queryIsEnriched(cur)
                ? acc[cur.ccy][f]
                : // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  (cur[f] as number) + acc[cur.ccy][f],
          }),
          {},
        ),
      }
    }
    return acc
  }, {} as SummationTotals)
  if (!useScenarioAnalysis) {
    return (
      <BasePricer
        data={dataWithResults}
        fieldSums={fieldSums}
        isLoading={isLoading}
        setSource={setSource}
        source={source}
      />
    )
  }
  return (
    <>
      <Page padding="12px">
        <BasePricer
          data={dataWithResults}
          fieldSums={fieldSums}
          isLoading={isLoading}
          setSource={setSource}
          source={source}
        />
        <ScenarioAnalysisWrapper
          data={dataWithResults}
          fieldSums={fieldSums}
          source={source}
        />
      </Page>
    </>
  )
}

export default OptionPricer
