import { isAfter, isEqual, isWithinInterval, parse } from 'date-fns'
import { Movement } from 'types/Monitors'

export const getLiveMovement = (value: number | null, prev?: number | null) => {
  if (!prev || !value || value === prev) return Movement.EQUAL
  if (value < prev) return Movement.DOWN
  return Movement.UP
}

export const getChange = (
  futures: { qualifiedName: string }[],
  series: 'px' | 'oi',
) =>
  futures.map((f) => ({
    type: 'FunctionCall',
    name: 'PercentChange',
    args: [
      {
        type: 'Identifier',
        name: f.qualifiedName.replace('tick', '1h').replace('px', series),
      },
      {
        type: 'NumberLiteral',
        name: '24',
      },
    ],
  }))

export function moneyFormat(val: number | string) {
  let m: string
  if (Math.abs(Number(val)) < 1.0e3) {
    m = `${val}`
  } else if (Math.abs(Number(val)) < 1.0e5) {
    m = `${(Math.abs(Number(val)) / 1.0e3).toFixed(2)}K`
  } else if (Math.abs(Number(val)) >= 1.0e5 && Math.abs(Number(val)) < 1.0e9) {
    m = `${(Math.abs(Number(val)) / 1.0e6).toFixed(2)}M`
  } else {
    m = `${(Math.abs(Number(val)) / 1.0e9).toFixed(2)}B`
  }
  return m
}

const isWithinDates = (
  dateString: string,
  range: { from: Date; until: Date },
) => {
  if (!dateString || Number.isNaN(Date.parse(dateString))) return true
  const date = new Date(dateString)
  if (range.until && range.from) {
    return (
      isWithinInterval(new Date(date), {
        start: range.from,
        end: range.until,
      }) ||
      (isEqual(date, range.from) && isEqual(date, range.until))
    )
  }
  if (!range.until && range.from) {
    return isAfter(date, range.from) || isEqual(date, range.from)
  }
  return false
}

export const COLUMN_MAPPING = {
  p: 'Last Traded',
  pd24: 'Price (24hr change)',
  oi: 'OI',
  v24: '24hr Vol',
  vv: 'Last',
  vvdytd: 'YTD',
  vvd24: '24hr change',
  rhodytd: 'YTD',
  rhod24: '24hr change',
  iv: 'Mid IV',
  iv30dmax: 'IV 1M Max',
  iv30dmin: 'IV 1M Min',
  fly: '25 Delta',
  flydytd: 'YTD',
  flyd24: '24hr change',
  atm: 'ATM',
  atmdytd: 'YTD',
  atmd24: '24hr change',
  skew: '25 Delta',
  skewdytd: 'YTD',
  skewd24: '24hr change',
}

export const expiryToDate = (s: string) => parse(s, 'dLLLy', new Date())
