import { CheckIcon } from '@radix-ui/react-icons'
import { ItemText } from '@radix-ui/react-select'

import { useDefaultStore } from 'stores'
import { Source, Ticker } from 'types'
import ControlledSelect, {
  SelectItem,
  SelectItemIndicator,
} from 'components/atoms/Select/Index'

import CurrencyLogo from 'components/charts/common/CurrencyLogo'
import { capitalize } from 'lodash'

const currencies = Object.values(Ticker)

const Controls = () => {
  const ccy = useDefaultStore((s) => s.monitors.currency)
  const exchange = useDefaultStore((s) => s.monitors.exchange)
  const setCurrency = useDefaultStore((s) => s.monitors.setCurrency)
  const setExchange = useDefaultStore((s) => s.monitors.setExchange)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ textTransform: 'uppercase', margin: 0, color: 'white' }}>
            Currency:
          </p>
          <ControlledSelect
            colorShade="blue6"
            value={ccy}
            valueDisplay={<CurrencyLogo currency={ccy} />}
            onValueChange={(v) => setCurrency(v)}
          >
            {currencies.map((c) => (
              <SelectItem value={c} key={c}>
                <CurrencyLogo currency={c} />
                <SelectItemIndicator>
                  <CheckIcon />
                </SelectItemIndicator>
                <ItemText>{c}</ItemText>
              </SelectItem>
            ))}
          </ControlledSelect>
        </div>
        <div
          style={{ paddingLeft: '2rem', display: 'flex', alignItems: 'center' }}
        >
          <p style={{ textTransform: 'uppercase', margin: 0, color: 'white' }}>
            Exchange:
          </p>
          <ControlledSelect
            colorShade="blue6"
            value={capitalize(exchange as string)}
            onValueChange={(v) => setExchange(v)}
          >
            {[Source.DERIBIT].map((c) => (
              <SelectItem value={c} key={c}>
                <SelectItemIndicator>
                  <CheckIcon />
                </SelectItemIndicator>
                <ItemText>{c}</ItemText>
              </SelectItem>
            ))}
          </ControlledSelect>
        </div>
      </div>
    </div>
  )
}

export default Controls
