/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { OptionPricerSlice } from 'stores/types'
import { immer } from 'zustand/middleware/immer'
import { v4 as uuidv4 } from 'uuid'
import { OptionType, Ticker } from 'types'
import { Model } from 'types/models'
import { AnalyseScenario, EditableOptionPriceQuery } from 'types/queries'
import { StateCreator } from 'zustand'

const defaultOption = () => ({
  inEdit: true,
  model: Model.SABR,
  ccy: Ticker.BTC,
  quantity: 1,
  type: OptionType.CALL,
  pricingTimestamp: 'LATEST' as const,
  id: uuidv4(),
})

const createOptionPricer: StateCreator<
  OptionPricerSlice,
  [],
  [['zustand/immer', never]],
  OptionPricerSlice
> = immer((set) => ({
  options: {
    analysis: {
      fetched: false,
      ladder: AnalyseScenario.SPOT,
      currency: null,
    },
    queries: [],
    setAnalysisLadder: (scenarioAnalysisLadder) =>
      set((state) => {
        state.options.analysis.ladder = scenarioAnalysisLadder
        state.options.analysis.fetched = false
      }),
    updateOption: (query, i) =>
      set((state) => {
        state.options.queries[i] = query
      }),
    addOption: (q?: EditableOptionPriceQuery) =>
      set((state) => {
        state.options.queries.push(q ?? defaultOption())
      }),
    deleteOption: (i) =>
      set((state) => {
        state.options.queries.splice(i, 1)
      }),
    setAnalysisFetched: () =>
      set((s) => {
        s.options.analysis.fetched = true
      }),
    setAnalysisCurrency: (t: Ticker) =>
      set((s) => {
        s.options.analysis.currency = t
        s.options.analysis.fetched = false
      }),
    setDefaults: (queries) =>
      set((state) => {
        state.options.queries = queries
      }),
  },
}))

export default createOptionPricer
