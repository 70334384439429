import { DataFrequency, splitCurrency, TenorOrExpiryKey } from 'services'
import { AxisFormat, Source, Ticker, YAxis } from 'types'
import AssetClass from 'types/assetClass'
import { Model, SABRParam, SVIParam } from 'types/models'

export type DataKeyOptionMiddle = {
  currency: Ticker
  model: Model
  tenor: `${number}d`
  type?: string
  param?: SABRParam | SVIParam
  timestamp?: 'LATEST' | Date
}

export type DataKeyRealVol = {
  currency: Ticker
  tenor: ''
  type: 'vol'
  timestamp?: 'LATEST' | Date
}

export type DataKeyListedOptionMiddle = {
  currency: Ticker
  model: Model
  expiry: string
  type?: string
  param?: SABRParam | SVIParam
  timestamp?: 'LATEST' | Date
}

export type DataKeyPerpMiddle = {
  perp: string
  type?: string
}

export type DataKeyFutureMiddle = {
  currency: Ticker
  tenor: `${number}d`
  timestamp?: 'LATEST' | Date
  type?: string
}

export type DataKeyListedExpiryMiddle = {
  listed: string
  type?: string
}

type DataKeyNarrowResult = {
  tenor: `${number}d`
  currency: Ticker
  model: Model
  series?: string // Used to narrow the series when array is returned
}

type DataKeyNarrowExpiryResult = {
  expiry: string
  currency: Ticker
  model: Model
  series?: string // Used to narrow the series when array is returned
}

export interface ConstructKey {
  source: Source
  assetClass: AssetClass
  suffix: string
  frequency?: DataFrequency
  middle:
    | DataKeyListedExpiryMiddle
    | DataKeyListedOptionMiddle
    | DataKeyOptionMiddle
    | DataKeyRealVol
    | DataKeyPerpMiddle
    | DataKeyFutureMiddle
    | DataKeyNarrowResult
  // | {}
  timestamp?: 'LATEST' | Date
  axis: {
    yAxis: YAxis
  }
  color: string
}

export interface SmileKey extends ConstructKey {
  middle: DataKeyNarrowResult | DataKeyNarrowExpiryResult
}

export const constructKey = ({
  source,
  assetClass,
  suffix,
  frequency,
  middle,
}: Omit<ConstructKey, 'color' | 'axis'>): string => {
  /* Data Keys are constructed as one of the following
  A) Source asset currency model/tenor tenor/freq freq/type suffix - 7 ( -deribit.option.BTC.SVI.120d.1h.smile, composite.option.ETH.30d.1h.index.vol)
  B) Source asset currency&date freq suffix - 5 (-deribit.future.BTC-29SEP23.1h.oi)
  C) source asset perp freq type suffix - 6   -deribit.perpetual.ETH-PERPETUAL.1h.funding.rate
  */
  if (!frequency) return ''
  const baseString = `${source.toLowerCase()}.${assetClass.toLowerCase()}`
  if ('model' in middle && 'type' in middle) {
    // deribit.option.BTC.SVI.120d.1h.smile
    // deribit.option.BTC.SVI.30d.1h.vol.ratio
    if ('tenor' in middle) {
      return `${baseString}.${middle.currency}${
        middle.model ? `.${middle.model}` : ''
      }.${
        middle.tenor.endsWith('d') ? middle.tenor : `${middle.tenor}d`
      }.${frequency}.${middle.type ? `${middle.type}.${suffix}` : suffix}`
    } else if ('expiry' in middle) {
      return `${baseString}.${middle.currency}${
        middle.model ? `.${middle.model}` : ''
      }.listed.${frequency}.${
        middle.type ? `${middle.type}.${suffix}` : suffix
      }`
    }
  }
  if ('model' in middle && !('type' in middle)) {
    // deribit.option.BTC.SVI.120d.1h.smile
    if ('tenor' in middle) {
      return `${baseString}.${middle.currency}.${middle.model}.${
        middle.tenor.endsWith('d') ? middle.tenor : `${middle.tenor}d`
      }.${frequency}.${suffix}`
    } else if ('expiry' in middle) {
      return `${baseString}.${middle.currency}.${middle.model}.listed.${frequency}.${suffix}`
    }
  }
  if (assetClass === AssetClass.PERP && 'perp' in middle) {
    // HACK UNTIL WE USE CATALOG
    if (source === Source.BITMEX) {
      const BITMEX_MAP = {
        'ETH-PERPETUAL': 'ETHUSD',
        'BTC-PERPETUAL': 'XBTUSD',
      }
      return `${baseString}.${BITMEX_MAP[middle.perp]}.${frequency}.${
        middle.type ? `${middle.type}.${suffix}` : suffix
      }`
    }
    if (source === Source.BYBIT) {
      const BYBIT_MAP = {
        'ETH-PERPETUAL': 'ETHPERP',
        'BTC-PERPETUAL': 'BTCPERP',
      }
      return `${baseString}.${BYBIT_MAP[middle.perp]}.${frequency}.${
        middle.type ? `${middle.type}.${suffix}` : suffix
      }`
    }
    // Perps have either 5 or 6 length due to no need for tenor/expiry
    // deribit.perpetual.ETH-PERPETUAL.1h.funding.rate
    return `${baseString}.${middle.perp}.${frequency}.${
      middle.type ? `${middle.type}.${suffix}` : suffix
    }`
  }
  if ('listed' in middle && suffix === 'curve') {
    return `${baseString}.${
      splitCurrency(middle.listed as TenorOrExpiryKey)[0]
    }.${middle.listed}d.${frequency}.${
      middle.type ? `${middle.type}.${suffix}` : suffix
    }`
  }
  if ('listed' in middle) {
    // deribit.future.BTC-29SEP23.1h.oi
    return `${baseString}.${middle.listed}.${frequency}.${
      middle.type ? `${middle.type}.${suffix}` : suffix
    }`
  }
  if ('tenor' in middle && !('model' in middle)) {
    // composite.option.ETH.30d.1h.index.vol
    return `${baseString}.${middle.currency}.${
      middle.tenor.endsWith('d') ? middle.tenor : `${middle.tenor}d`
    }.${frequency}.${
      'type' in middle && middle.type ? `${middle.type}.${suffix}` : suffix
    }`
  }
  console.error('Could not construct data key')
  return ''
}

// TODO:  MOVE AWAY FROM THIS UGLY DECONSTRUCT KEY STUFF (Sorry if you stumble on it, was all done in rush previously)
export const deconstructKey = (key?: string) => {
  if (!key) return undefined
  const splitKey = key.split('.')
  let dKey: Omit<ConstructKey, 'color' | 'axis'> | undefined = undefined
  if (splitKey.length === 9) {
    const [
      exchange,
      asset,
      currency,
      model,
      listed,
      exp,
      frequency,
      type,
      suffix,
    ] = splitKey

    dKey = {
      source: exchange as Source,
      assetClass: asset as AssetClass,
      suffix,
      frequency: frequency as DataFrequency,
      middle: {
        currency: currency as Ticker,
        model: model as Model,
        expiry: exp,
        type,
      },
    }
  }
  if (splitKey.length === 8) {
    const [exchange, asset, currency, model, tenor, frequency, type, suffix] =
      splitKey

    dKey = {
      source: exchange as Source,
      assetClass: asset as AssetClass,
      suffix,
      frequency: frequency as DataFrequency,
      middle: {
        currency: currency as Ticker,
        model: model as Model,
        tenor: tenor as `${number}d`,
        type,
      },
    }
    if (tenor === 'listed') {
      dKey.middle['expiry'] = frequency
      // dKey.middle['type'] = undefined
      dKey['frequency'] = type as DataFrequency
    } else {
      dKey.middle['tenor'] = tenor
    }
  }
  if (splitKey.length === 7) {
    // deribit.option.BTC.SVI.120d.1h.smile
    if (Object.values<string>(Model).includes(splitKey[3])) {
      const [exchange, asset, currency, model, tenor, frequency, suffix] =
        splitKey
      dKey = {
        source: exchange as Source,
        assetClass: asset as AssetClass,
        suffix,
        frequency: frequency as DataFrequency,
        middle: {
          currency: currency as Ticker,
          model: model as Model,
          tenor: tenor as `${number}d`,
        },
      }
    } else {
      const [exchange, asset, currency, tenor, frequency, type, suffix] =
        splitKey
      dKey = {
        source: exchange as Source,
        assetClass: asset as AssetClass,
        suffix,
        frequency: frequency as DataFrequency,
        middle: {
          currency: currency as Ticker,
          tenor: tenor as `${number}d`,
          type,
        },
      }
    }
  }
  if (splitKey.length === 6) {
    // deribit.perpetual.ETH-PERPETUAL.1h.funding.rate
    if (splitKey[1] === 'perpetual') {
      const [exchange, perpAsset, perp, frequency, type, suffix] = splitKey
      dKey = {
        source: exchange as Source,
        assetClass: perpAsset as AssetClass.PERP,
        suffix,
        frequency: frequency as DataFrequency,
        middle: {
          perp,
          type,
        },
      }
    } else if (splitKey[2].includes('-')) {
      // Listed expiry
      const [exchange, asset, listed, frequency, type, suffix] = splitKey
      dKey = {
        source: exchange as Source,
        assetClass: asset as AssetClass.FUTURE,
        suffix,
        frequency: frequency as DataFrequency,
        middle: {
          listed,
          type,
        },
      }
    } else {
      // deribit.future.BTC.3M.1h.px
      const [exchange, asset, currency, tenor, frequency, suffix] = splitKey
      dKey = {
        source: exchange as Source,
        assetClass: asset as AssetClass.FUTURE,
        suffix,
        frequency: frequency as DataFrequency,
        middle: {
          currency: currency as Ticker,
          tenor: tenor as `${number}d`,
        },
      }
    }
  }
  if (splitKey.length === 5) {
    if (splitKey[1] === 'perpetual') {
      // deribit.perpetual.ETH-PERPETUAL.1h.px
      const [exchange, asset, perp, frequency, suffix] = splitKey
      dKey = {
        source: exchange as Source,
        assetClass: asset as AssetClass.PERP,
        suffix,
        frequency: frequency as DataFrequency,
        middle: {
          perp,
        },
      }
    } else {
      const [exchange, asset, listed, frequency, suffix] = splitKey
      dKey = {
        source: exchange as Source,
        assetClass: asset as AssetClass.FUTURE,
        suffix,
        frequency: frequency as DataFrequency,
        middle: {
          listed,
        },
      }
    }
  }
  return dKey
}

export const getFormat = (key: string) => {
  const split = key.split('.')
  const suffix = split[split.length - 1]

  if (
    [
      'pct',
      'rate',
      'vol',
      'smile',
      'ratio',
      'delta',
      'yield',
      'rho',
      'atm',
      'realized',
    ].find((s) => suffix.includes(s))
  ) {
    return 'p2'
  }
  if (['px', 'curve'].find((s) => s === suffix)) {
    return 'c0'
  }
  if (suffix === 'oi') {
    if (!key.toLowerCase().includes('option')) return 'c0' // options don't have monetary value
    return 'n2'
  }
  if (suffix === 'sum') {
    if (!key.toLowerCase().includes('option')) return 'c0' // options don't have monetary value
    return 'n2'
  }
  return 'n2'
}

export const getCrossingVals = (
  endVal: number | string,
  axis: Partial<Record<YAxis, AxisFormat>>,
) => {
  let val
  if (Object.keys(axis).length === 2 && axis.LEFT && axis.RIGHT) {
    val = [0, endVal]
  } else if (axis.LEFT) {
    val = [0]
  } else {
    val = [endVal]
  }
  return val
}
