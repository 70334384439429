import * as Toolbar from '@radix-ui/react-toolbar'
import styled, { useTheme } from 'styled-components'
import {
  OpenInNewWindowIcon,
  EnterFullScreenIcon,
  ExitFullScreenIcon,
} from '@radix-ui/react-icons'
import * as Separator from '@radix-ui/react-separator'
import { useDefaultStore } from 'stores'

import SingleDateLatestPicker from 'components/molecules/DateLatestPicker/SingleDateLatestPicker'
import ToggleGroup, { ToggleGroupItem } from 'components/atoms/ToggleGroup'
import FrequencyChange from 'components/molecules/FrequencyChange'
import { dateFormatter } from 'utils/date-formatter'
import { Chart, DateRange, Frequency, LookBackWindow, TimeZone } from 'types'
import { ChartState } from 'stores/types'
import ToolTip from 'components/atoms/tooltip'
import { usePermissions } from 'hooks/usePermissions'
import LastUpdated from './series/LastUpdated'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { H6 } from 'components/atoms/Typography/styles'

const TbRoot = styled(Toolbar.Root)<{ width?: string; bottom?: string }>`
  width: ${({ width }) => width || '95%'};
  min-width: max-content;
  display: flex;
  padding: 6px;
  border-radius: 12px;
  // box-shadow: 0 0px 6px ${({ theme }) => theme.palette.common.blue5};
  border: 1px solid ${({ theme }) => theme.palette.common.blue4};
  position: relative;
`

const BaseToolBar = ({
  children,
  label,
  width,
}: {
  children: JSX.Element | JSX.Element[]
  label: string
  width?: string
}) => {
  return (
    <TbRoot className="ToolbarRoot" aria-label={label} width={width}>
      <div
        style={{
          width: '100%',
          height: '40px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {children}
      </div>
    </TbRoot>
  )
}
export const ChartTopToolbar = ({
  id,
  frequency,
  updateFrequency,
  type,
  disableMinutes,
  topBarContent,
  importSeriesToHA,
  addSeries,
  title,
  infoButton,
}: {
  id: string
  frequency: ChartState['frequency']
  updateFrequency: (f: ChartState['frequency']) => void
  type?: Chart
  disableMinutes?: boolean
  topBarContent?: JSX.Element
  addSeries?: JSX.Element
  importSeriesToHA?: () => void
  title: string
  infoButton?: React.ReactNode
}): JSX.Element => {
  const enlargeChart = useDefaultStore((state) => state.setExpandedChart)
  const chartEnlarged = useDefaultStore((state) => state.expandedId)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const theme = useTheme()
  const { HAEnabled } = usePermissions()
  const Title = () => (
    <H6
      style={{
        color: theme.palette.primary.light,
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: '0.85rem',
      }}
    >
      {title} {infoButton}
    </H6>
  )

  return (
    <BaseToolBar label="Formatting options" width="100%">
      <div style={{ alignSelf: 'center', display: 'flex' }}>
        {type === Chart.TIMESERIES ||
        type === Chart.TIMESERIES_BAR ||
        type === Chart.TIMESERIES_AREA ? (
          <FrequencyChange
            disableMinutes={disableMinutes}
            frequency={frequency}
            updateFrequency={updateFrequency}
          />
        ) : (
          <></>
        )}
        {isMobile ? (
          <></>
        ) : (
          <>
            {addSeries || <></>}
            {topBarContent || <></>}
          </>
        )}
      </div>
      {/* When mobile we render the add series at the end as a '+' and want the title content in the middle */}
      {isMobile ? <Title /> : <></>}
      {isMobile ? (
        <>
          {addSeries || <></>}
          {topBarContent || <></>}
        </>
      ) : (
        <Title /> || <></>
      )}
      {!isMobile ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Separator.Root
            className="SeparatorRoot"
            decorative
            orientation="vertical"
            style={{
              margin: '0 15px',
              height: '100%',
              width: '1px',
              backgroundColor: 'grey',
            }}
          />
          <ToggleGroup>
            <ToolTip
              content={
                <div>{chartEnlarged === id ? 'Exit' : 'Enter'} full screen</div>
              }
            >
              <ToggleGroupItem
                height={24}
                width={24}
                value="full-screen"
                onClick={
                  chartEnlarged === id
                    ? () => enlargeChart(null)
                    : () => enlargeChart(id)
                }
                asChild
              >
                {chartEnlarged === id ? (
                  <ExitFullScreenIcon height={24} width={24} />
                ) : (
                  <EnterFullScreenIcon height={24} width={24} />
                )}
              </ToggleGroupItem>
            </ToolTip>
            {type?.startsWith('TIMESERIES') ? (
              <ToolTip
                content={
                  HAEnabled ? (
                    <div>Take a closer look in our Historical Analyzer.</div>
                  ) : (
                    <div>
                      To take a closer look in our Historical Analyzer you first
                      need to upgrade to Pro Subscription.
                    </div>
                  )
                }
              >
                <ToggleGroupItem height={24} width={24} value="import" asChild>
                  <OpenInNewWindowIcon onClick={importSeriesToHA} />
                </ToggleGroupItem>
              </ToolTip>
            ) : (
              <></>
            )}
          </ToggleGroup>
        </div>
      ) : (
        <></>
      )}
    </BaseToolBar>
  )
}

export const ChartBottomToolbar = ({
  timings,
  frequency,
  updateTimeRange,
  timeZone,
  updatedStamp,
  toggleTimeZone,
  shouldFormat,
}: {
  timings?: {
    single?: 'LATEST' | Date
    range?: LookBackWindow | DateRange
  }
  frequency: Frequency
  timeZone?: TimeZone
  updatedStamp?: Date
  updateTimeRange: (e: LookBackWindow | DateRange) => void
  toggleTimeZone: () => void
  shouldFormat?: boolean // TO DO - remove when all using bslang chart
}): JSX.Element => {
  const isMobile = useMediaQuery('(max-width: 768px)')

  return isMobile ? (
    <>
      {updatedStamp &&
        !Number.isNaN(updatedStamp.getTime()) &&
        typeof timings?.range !== 'object' &&
        timeZone && (
          <span style={{ fontSize: '0.6rem' }}>
            Last updated:{' '}
            <LastUpdated
              timeZone={timeZone}
              updatedStamp={updatedStamp}
              shouldFormat={shouldFormat}
            />
          </span>
        )}
    </>
  ) : (
    <>
      <BaseToolBar label="Top chart action options">
        <SingleDateLatestPicker
          timeRange={timings?.range}
          frequency={frequency}
          updateTimeRange={updateTimeRange}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Separator.Root
            className="SeparatorRoot"
            decorative
            orientation="vertical"
            style={{
              margin: '0 15px',
              height: '100%',
              width: '1px',
              backgroundColor: 'grey',
            }}
          />
          {typeof timings?.range === 'object' &&
            'from' in timings.range &&
            `${dateFormatter.format(
              timings.range.from,
            )} - ${dateFormatter.format(timings.range.until)}`}
          {updatedStamp &&
            !Number.isNaN(updatedStamp.getTime()) &&
            typeof timings?.range !== 'object' &&
            timeZone && (
              <LastUpdated
                onClick={toggleTimeZone}
                timeZone={timeZone}
                updatedStamp={updatedStamp}
                shouldFormat={shouldFormat}
              />
            )}
        </div>
      </BaseToolBar>
      <p
        style={{
          opacity: '70%',
          fontSize: '0.9rem',
          margin: 0,
          textAlign: 'end',
          width: '100%',
          padding: '0 2rem',
        }}
      >
        Shift + scroll to zoom
      </p>
    </>
  )
}
