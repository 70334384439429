import React from 'react'

import { Source, Ticker } from 'types'
import { Model } from 'types/models'
import AssetClass from 'types/assetClass'

import useMultiSeriesChart from 'hooks/useMultiSeriesChart'
import MultiLineSeriesWrapper from '../common/series/MultiSeriesWrapper'
import { SeriesPills } from '../common/series/Selector'
import useDerivedActiveListedExpiry from 'hooks/useDerivedActiveListedExpiry'
import ChartLoader from '../ChartLoader'

const OptionsPutCallSkew: React.FC<{ availableTenors?: `${number}d`[] }> = ({
  availableTenors,
}) => {
  const title = 'Put/call skew'
  const { listedExpiries, isLoading } = useDerivedActiveListedExpiry({
    queryKeys: [`deribit.option.BTC.SVI.listed.1h.skew`],
    refetchInterval: Infinity,
    timestampRange: { range: '1d' }, // Short range just to get the possible expiries
    enabled: true,
    dataFreq: '1h',
    refetchOnWindowFocus: false,
  })

  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(
    title,
    [
      {
        assetClass: AssetClass.OPTION,
        middle: {
          model: Model.SVI,
          currency: Ticker.BTC,
          tenor: availableTenors?.[0] ?? '7d',
          series: '10delta',
        },
        suffix: 'skew',
        source: Source.DERIBIT,
      },
      {
        assetClass: AssetClass.OPTION,
        middle: {
          model: Model.SVI,
          currency: Ticker.BTC,
          tenor: availableTenors?.[0] ?? '7d',
          series: '25delta',
        },
        suffix: 'skew',
        source: Source.DERIBIT,
      },
      {
        isListedExpiry: true,
        expiry: listedExpiries[4],
        source: Source.DERIBIT,
        baseAsset: Ticker.BTC,
        model: Model.SVI,
        suffix: 'skew',
        field: '25delta',
      },
      {
        isListedExpiry: true,
        expiry: listedExpiries[2],
        source: Source.DERIBIT,
        baseAsset: Ticker.BTC,
        model: Model.SVI,
        suffix: 'skew',
        field: '25delta',
      },
    ],
  )

  return isLoading ? (
    <ChartLoader />
  ) : (
    <MultiLineSeriesWrapper
      chartSeries={chartSeries}
      title={title}
      id={title}
      axisLabel="%"
      axis={{ LEFT: 'p2' }}
      assetType={AssetClass.OPTION}
      series={['5delta', '10delta', '20delta', '25delta', '40delta']}
      useTypeToggle
      suffix="skew"
      useTenors
      availableTenors={availableTenors}
      listedExpiry={{ enabled: true }}
      fetchListedExpiries
      seriesPills={
        <SeriesPills
          chartSeries={chartSeries}
          id={title}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
    />
  )
}

export default OptionsPutCallSkew
