/* eslint-disable @typescript-eslint/no-explicit-any */
import { H6 } from 'components/atoms/Typography/styles'
import { useEffect } from 'react'
import { useDefaultStore, useLiveStore } from 'stores'
import { DataKey, KeyData, LiveActionTypes } from 'stores/types'
import { Source } from 'types'
import { Monitors } from 'types/Monitors'
import Monitor from '.'

const monitor = Monitors.MARKET_FUTURES
const FuturesMonitor = () => {
  const exchange = useDefaultStore((s) => s.monitors.exchange)
  const ccy = useDefaultStore((s) => s.monitors.currency)
  const dispatch = useLiveStore((s) => s.actions.dispatch)
  const allRowIds = useLiveStore(
    (s) => Array.from(s.monitors[monitor] || '') as DataKey[],
  )

  const data = useLiveStore((s) =>
    allRowIds.reduce((acc: [string, KeyData][], id) => {
      if (s.data[`${exchange.toLowerCase()}.${ccy}-${id}`]) {
        acc.push([id, s.data[`${exchange.toLowerCase()}.${ccy}-${id}`]])
      }
      return acc
    }, []),
  )

  useEffect(() => {
    if (exchange !== Source.BYBIT) {
      dispatch({
        type: LiveActionTypes.SUBSCRIBE,
        data: {
          streams: [
            {
              monitor: {
                id: `monitor.${exchange as Source}.${monitor}`,
              },
            },
          ],
        },
      })
      return () => {
        dispatch({
          type: LiveActionTypes.UN_SUBSCRIBE,
          data: {
            streams: [`monitor.${exchange as Source}.${monitor}`],
          },
        })
      }
    }
  }, [dispatch, exchange])
  return (
    <Monitor
      data={data}
      TopBarContent={() => (
        <H6 style={{ padding: '0.5rem' }}>Market Futures</H6>
      )}
      monitor={Monitors.MARKET_FUTURES}
    />
  )
}

export default FuturesMonitor
