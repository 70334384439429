import AssetClass from './assetClass'

export enum Source {
  DERIBIT = 'deribit',
  BYBIT = 'bybit',
  COMPOSITE = 'composite',
  LYRA_OPTIMISM = 'lyra-optimism',
  LYRA_ARBITRUM = 'lyra-arbitrum',
  BITMEX = 'bitmex',
  AEVO = 'aevo',
  OKX = 'okx',
}

export const AssetClassSource: Record<AssetClass, Array<Source>> = {
  [AssetClass.FUTURE]:
    process.env.REACT_APP_ENV === 'staging'
      ? [Source.DERIBIT, Source.AEVO, Source.OKX]
      : [Source.DERIBIT],
  [AssetClass.PERP]:
    process.env.REACT_APP_ENV === 'staging'
      ? [Source.DERIBIT, Source.BYBIT, Source.BITMEX, Source.AEVO, Source.OKX]
      : [Source.DERIBIT, Source.BYBIT, Source.BITMEX],
  [AssetClass.INDICES]: [Source.COMPOSITE],
  [AssetClass.OPTION]:
    process.env.REACT_APP_ENV === 'staging'
      ? [
          Source.DERIBIT,
          Source.BYBIT,
          Source.COMPOSITE,
          Source.LYRA_OPTIMISM,
          Source.LYRA_ARBITRUM,
          Source.AEVO,
          Source.OKX,
        ]
      : [Source.DERIBIT],
}

export const DeribitOnly = [Source.DERIBIT]
