import { CrossCircledIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import { QuoteAsset, Source, Ticker, YAxis } from 'types'

import { capitalize } from 'lodash'
import { splitCurrency, TenorOrExpiryKey } from 'services'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { format, parseISO } from 'date-fns'
import { useDefaultStore } from 'stores'
import {
  FutureTermStructureSeries,
  NewChartSeries,
  NewChartState,
  OptionSingleSeries,
  SeriesItem,
} from 'stores/types'
import AssetClass from 'types/assetClass'
import { isConstructKey } from 'types/typeGuards'
import { daysToTenor } from 'utils/charts'
import {
  shortDateFormatter,
  underlyingOrIsoToReadableFormat,
} from 'utils/date-formatter'
import CurrencyLogo from '../CurrencyLogo'
import { SmileKey } from '../util'
import { AddOrEditSeriesNew, EditSeries } from './AddSeries'
import { useMediaQuery } from 'hooks/useMediaQuery'

const getBGColor = (colour: string, theme: DefaultTheme) => {
  if (colour.startsWith('#')) {
    return `${colour}1a`
  }
  if (colour.startsWith('rgb(')) {
    return colour.replace('rgb(', 'rgba(').replace(')', '0.1)')
  }
  if (colour.startsWith('rgba(')) {
    return colour.replace('1)', '0.1)')
  }
  return theme.palette.background.base
}

export const StyledBasePill = styled.div<{
  color: string
  bgColor: string
  padding?: string
  fs?: string
  isMobile?: boolean
}>`
  display: inline-flex;
  font-size: ${({ fs, isMobile }) =>
    fs || (isMobile ? '0.6rem' : 'clamp(0.6rem,0.9rem,1.1rem)')};
  padding: ${({ padding, isMobile }) =>
    padding || (isMobile ? '0 0.25rem' : '0.2rem 0.3rem')};
  margin: ${({ isMobile }) => (isMobile ? '0.1rem' : '0.2rem')};
  border: 2px solid ${({ color }) => color};
  border-radius: 14px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  align-items: center;

  &:hover {
    transform: scale(1.03);
    cursor: pointer;
  }
  svg:hover {
    background-color: ${({ color }) => color};
    color: ${({ theme }) => theme.palette.common.blue2};
    border-radius: 14px;
  }
`
const BasePill = ({
  currency,
  children,
  handleDelete,
  index,
  color = 'white',
  seriesId,
  setHighlighted,
}: {
  currency?: QuoteAsset
  color: string
  index: number
  seriesId: string
  children: React.ReactChild | React.ReactChild[]
  handleDelete: () => void
  setHighlighted?: (n: number | undefined) => void
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery('(max-width: 768px)')

  const bgColour = getBGColor(color, theme)
  const [isOpen, setIsOpen] = useState(false)
  const editSeries = useDefaultStore((state) => state.editSeries)
  const chartAxis = useDefaultStore((state) => {
    const chart = state.defaultCharts[seriesId]
    if (chart && 'axis' in chart) {
      return chart.axis
    }
  })
  const series = useDefaultStore((state) => {
    const chart = state.defaultCharts[seriesId]
    if (chart && 'series' in chart && chart.series) {
      return chart.series[index]
    }
  })
  const seriesLength = useDefaultStore((state) => {
    const chart = state.defaultCharts?.[seriesId]
    if (chart && 'series' in chart) {
      return chart.series?.length || 0
    }
    return 0
  })

  const updateSeries = (
    key: 'color' | 'axis',
    val: string | { yAxis: YAxis },
  ) => {
    editSeries(seriesId, { indx: index, key, val })
  }
  const updateAxis = (a: YAxis) => {
    editSeries(seriesId, {
      indx: index,
      key: 'axis',
      val: { yAxis: a },
    })
  }

  return (
    <EditSeries
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      color={color}
      axis={series?.axis?.yAxis}
      chartAxis={chartAxis}
      setAxis={updateAxis}
      seriesLength={seriesLength}
      setColor={(c) => updateSeries('color', c)}
    >
      <StyledBasePill
        onClick={() => setIsOpen(true)}
        color={color}
        bgColor={bgColour}
        isMobile={isMobile}
        onMouseEnter={setHighlighted ? () => setHighlighted(index) : () => {}}
        onMouseLeave={
          setHighlighted ? () => setHighlighted(undefined) : () => {}
        }
      >
        <CurrencyLogo
          currency={currency}
          height={isMobile ? 12 : undefined}
          width={isMobile ? 12 : undefined}
        />
        {children}
        <CrossCircledIcon
          height={isMobile ? 12 : 16}
          width={isMobile ? 12 : 16}
          style={{
            position: 'relative',
            margin: '1px',
            right: '-2px',
          }}
          onClick={() => {
            handleDelete()
            if (setHighlighted) {
              setHighlighted(undefined)
            }
          }}
        />
      </StyledBasePill>
    </EditSeries>
  )
}

export const Pill = ({
  series,
  index,
  handleDelete,
  hasSameType,
  seriesId,
  hasSameAxis,
  hasSameSuffix,
  setHighlighted,
}: {
  series: SeriesItem
  handleDelete: (i: number) => void
  index: number
  hasSameType?: boolean
  seriesId: string
  hasSameAxis: boolean
  hasSameSuffix: boolean
  setHighlighted?: (n: number | undefined) => void
}) => {
  const sourceLabel =
    series.source.split('-').length > 1
      ? `${capitalize(series.source.split('-')[0])} (${capitalize(
          series.source.split('-')[1],
        )})`
      : capitalize(series.source)
  let label = `${sourceLabel} `
  if (isConstructKey(series)) {
    if ('tenor' in series.middle && series.middle.tenor) {
      label = label.concat(
        `${daysToTenor(series.middle.tenor) || series.middle.tenor}`,
      )
    } else if ('listed' in series.middle) {
      label = label.concat(
        `${splitCurrency(series.middle.listed as TenorOrExpiryKey)[1]}`,
      )
      if (series.middle.listed.split('-').length > 2) {
        // option contract
        label = label.concat(
          ` ${series.middle.listed.split('-')[2]} ${
            series.middle.listed.split('-')[3]
          }`,
        )
      }
    }
    if ('type' in series.middle && series.middle.type && !hasSameType) {
      label = label.concat(` ${capitalize(series.middle.type)}`)
    }
    if ('model' in series.middle && series.middle.model) {
      label = label.concat(` ${series.middle.model}`)
      if ('param' in series.middle && series.middle.param) {
        label = label.concat(` ${series.middle.param}`)
      }
    }
    if ('series' in series.middle && series.middle.series) {
      label = label.concat(` ${series.middle.series}`)
    }
    if (!hasSameSuffix) {
      label = label.concat(` ${capitalize(series.suffix)}`)
    }
  } else {
    const expiry = underlyingOrIsoToReadableFormat(series?.expiry)
    label = `${label} ${expiry} ${series.model ? series.model : ''} ${
      series.field && !hasSameType ? series.field : ''
    }` // TODO: HACK until v2 atm can be used for atm chart
    if (!hasSameSuffix) {
      const suffix = series.suffix.split('.')
      label = label.concat(` ${capitalize(suffix[suffix.length - 1])}`)
    }
  }
  if (!hasSameAxis) {
    label =
      series.axis.yAxis === YAxis.LEFT
        ? label.concat(' (LHS)')
        : label.concat(' (RHS)')
  }
  let currency
  if ('baseAsset' in series) {
    currency = series.baseAsset
  } else {
    if ('currency' in series.middle && series.middle.currency) {
      currency = series.middle.currency
    }
    if ('listed' in series.middle && series.middle.listed) {
      currency = splitCurrency(
        series.middle?.listed as TenorOrExpiryKey,
      )[0] as Ticker
    }
    if ('perp' in series.middle && series.middle.perp) {
      currency = splitCurrency(
        series.middle?.perp as TenorOrExpiryKey,
      )[0] as Ticker
    }
  }
  return (
    <BasePill
      index={index}
      color={series.color}
      currency={currency}
      seriesId={seriesId}
      handleDelete={() => handleDelete(index)}
      setHighlighted={setHighlighted}
    >
      {label}
    </BasePill>
  )
}

export const PillNew = ({
  index,
  handleDelete,
  handleUpdate,
  hasSameType,
  chartId,
  series,
  assetClass,
  hasSameAxis,
  setHighlighted,
  sources,
  getAvailableSeries,
  currencies,
  initial,
}: {
  handleDelete: (i: number) => void
  handleUpdate: (s: any, i: number) => void
  index: number
  hasSameType?: boolean
  chartId: string
  series: NewChartSeries
  hasSameAxis: boolean
  setHighlighted?: (n: number | undefined) => void
  assetClass: AssetClass
  sources?: Source[]
  getAvailableSeries?: (
    s: Source,
    ccy: Ticker | `${Ticker}/${QuoteAsset}`,
  ) => [string[], 'instrument' | 'expiry' | undefined]
  currencies: Ticker[] | `${Ticker}/${QuoteAsset}`[]
  initial: Exclude<NewChartState['add'], undefined>
}) => {
  const split = series?.qualifiedName?.split('.')
  let label = capitalize(split[0])
  if (series.listed) {
    if (assetClass === AssetClass.PERP) {
      label = `${label} ${series.base}/${series.quote}`
    }
    if (assetClass === AssetClass.OPTION) {
      label = `${label} ${split[2].replaceAll(/-/g, ' ')}`
    }
    if (assetClass === AssetClass.FUTURE) {
      label = `${label} ${
        series?.expiry
          ? format(new Date(series?.expiry), 'ddLLLyy').toUpperCase()
          : split[2].replaceAll(/-/g, ' ')
      } ${series.base}/${series.quote}`
    }
  }

  if (!hasSameAxis) {
    label =
      series.axis.yAxis === YAxis.LEFT
        ? label.concat(' (LHS)')
        : label.concat(' (RHS)')
  }
  const theme = useTheme()

  const bgColour = getBGColor(series.color, theme)
  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <AddOrEditSeriesNew
      initial={initial}
      seriesIndex={index}
      addSeries={handleUpdate}
      id={chartId}
      sources={sources}
      assetClass={assetClass}
      submitText="Update"
      getAvailableSeries={getAvailableSeries}
      currencies={currencies}
      trigger={
        <StyledBasePill
          color={series.color}
          isMobile={isMobile}
          bgColor={bgColour}
          onMouseEnter={setHighlighted ? () => setHighlighted(index) : () => {}}
          onMouseLeave={
            setHighlighted ? () => setHighlighted(undefined) : () => {}
          }
        >
          <CurrencyLogo
            currency={series.base}
            height={isMobile ? 12 : undefined}
            width={isMobile ? 12 : undefined}
          />
          {label}
          <CrossCircledIcon
            height={isMobile ? 12 : 16}
            width={isMobile ? 12 : 16}
            style={{
              position: 'relative',
              margin: '1px',
              right: '-2px',
            }}
            onClick={() => {
              handleDelete(index)
              if (setHighlighted) {
                setHighlighted(undefined)
              }
            }}
          />
        </StyledBasePill>
      }
    />
  )
}

export const TermStructPill = ({
  series,
  index,
  handleDelete,
  date,
  seriesId,
  hasSameAxis,
  setHighlightedSeries,
}: {
  series: OptionSingleSeries | FutureTermStructureSeries | SmileKey
  handleDelete: (i: number) => void
  index: number
  date: string
  seriesId: string
  hasSameAxis: boolean
  setHighlightedSeries: (n: number | undefined) => void
}): JSX.Element => {
  let label = `${capitalize(series.source)} `
  if ('middle' in series && 'tenor' in series.middle) {
    label = label.concat(`${daysToTenor(series.middle.tenor)}`)
  }
  if ('middle' in series && 'expiry' in series.middle) {
    label = label.concat(
      `${underlyingOrIsoToReadableFormat(series.middle.expiry)}`,
    )
  }
  if ('type' in series && series.type) {
    label = label.concat(` ${series.type}`)
  }
  if ('middle' in series && 'model' in series.middle && series.middle.model) {
    label = label.concat(` ${series.middle.model}`)
  }
  if ('model' in series && series.model) {
    label = label.concat(` ${series.model.type}`)
    if ('type' in series.model && series.model.param) {
      label = label.concat(` ${series.model.param}`)
    }
  }
  if (!hasSameAxis) {
    label =
      series.axis.yAxis === YAxis.LEFT
        ? label.concat(' (LHS)')
        : label.concat(' (RHS)')
  }

  return (
    <BasePill
      color={series.color}
      seriesId={seriesId}
      index={index}
      currency={'currency' in series ? series.currency : series.middle.currency}
      handleDelete={() => handleDelete(index)}
      setHighlighted={setHighlightedSeries}
    >
      {label} {date && shortDateFormatter.format(new Date(date))}
    </BasePill>
  )
}
