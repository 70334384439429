import { add, sub } from 'date-fns'
import { Ticker } from 'types/ticker'
import { ReferralMap } from 'types/register'

export const MAX_TERM_DAYS = 365

export const ACTIVE_TICKERS = [Ticker.BTC, Ticker.ETH]

export const API_ROOT_URL: string =
  process.env.REACT_APP_ENV === 'staging'
    ? 'https://staging-api.blockscholes.com/api/'
    : process.env.REACT_APP_BLOCKSCHOLES_API_ENDPOINT || ''
if (!API_ROOT_URL) {
  throw new Error(
    'API endpoint is not defined in environment at REACT_APP_BLOCKSCHOLES_API_ENDPOINT',
  )
}

export const TenorDays: Record<string, number> = {
  '1d': 1,
  '7d': 7,
  '1W': 7,
  '2W': 14,
  '1M': 30,
  '2M': 60,
  '3M': 90,
  '4M': 120,
  '5M': 150,
  '6M': 180,
  '1Y': 365,
}

export const DaysTenor: Record<number, string> = {
  7: '1W',
  14: '2W',
  21: '3W',
  30: '1M',
  60: '2M',
  90: '3M',
  120: '4M',
  150: '5M',
  180: '6M',
  210: '7M',
  240: '8M',
  270: '9M',
  300: '10M',
  330: '11M',
  365: '1Y',
  730: '2Y',
}
export const START_OF_BLOCKSCHOLES_TIME = new Date(Date.UTC(2020, 0))
export const TODAY_LOCAL = new Date()
export const NOW_UTC = (useMinutes = true): Date =>
  new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      useMinutes ? new Date().getUTCMinutes() : 0,
    ),
  )
export const SIX_MONTHS_AGO = sub(NOW_UTC(), { months: 6 })
export const THREE_MONTHS_AGO = sub(NOW_UTC(), { months: 3 })
export const SEVEN_DAYS_AHEAD = add(NOW_UTC(), { days: 8 })
export const getXDaysAhead = (d: number): Date => add(NOW_UTC(), { days: d })
export const getOneDayAgo = (): Date => sub(NOW_UTC(), { days: 1 })
export const getXDaysAgo = (d: number): Date => sub(NOW_UTC(), { days: d })
export const TWO_YEARS_AHEAD = add(NOW_UTC(), { years: 2 })
export const ONE_YEAR_AHEAD = add(NOW_UTC(), { years: 1 })
export const MS_PER_DAY = 86400000

export const SMILE_CATEGORIES = [
  '-1delta',
  '-5delta',
  '-10delta',
  '-15delta',
  '-20delta',
  '-25delta',
  '-30delta',
  '-40delta',
  '50delta',
  '40delta',
  '30delta',
  '25delta',
  '20delta',
  '15delta',
  '10delta',
  '5delta',
  '1delta',
]

export const DATE_FORMAT = 'dd MMM yy'
export const DATETIME_FORMAT = `${DATE_FORMAT}, HH:00`
export const DATETIME_FORMAT_WITH_MINUTES = `${DATE_FORMAT}, HH:mm`

// TODO: move colors into own file and migrate everything to use RgbColor type and helpers
// Experiment to use TypeScript 4.5 features to enforce type safety around a number range
type Range<T extends number> = number extends T ? number : _Range<T, []>
type _Range<T extends number, R extends unknown[]> = R['length'] extends T
  ? R[number]
  : _Range<T, [R['length'], ...R]>
type RgbColorValue = Range<256>

export type RgbColor = {
  red: RgbColorValue
  green: RgbColorValue
  blue: RgbColorValue
}

export const HEATMAP_NEGATIVE_COLOR: RgbColor = {
  red: 45,
  green: 115,
  blue: 245,
} as const

export const HEATMAP_ZERO_COLOR: RgbColor = {
  red: 120,
  green: 210,
  blue: 55,
} as const

export const HEATMAP_POSITIVE_COLOR: RgbColor = {
  red: 255,
  green: 99,
  blue: 88,
} as const

export const HEATMAP_COLORS = {
  R_MIN: HEATMAP_NEGATIVE_COLOR.red,
  G_MIN: HEATMAP_NEGATIVE_COLOR.green,
  B_MIN: HEATMAP_NEGATIVE_COLOR.blue,
  R_MID: HEATMAP_ZERO_COLOR.red,
  G_MID: HEATMAP_ZERO_COLOR.green,
  B_MID: HEATMAP_ZERO_COLOR.blue,
  R_MAX: HEATMAP_POSITIVE_COLOR.red,
  G_MAX: HEATMAP_POSITIVE_COLOR.green,
  B_MAX: HEATMAP_POSITIVE_COLOR.blue,
}

export const integrationMap: ReferralMap = {
  'bit-bns': {
    name: 'Bitbns',
    id: 'bit-bns',
    logo: 'https://bitbns.com/assets/img/logos/bitbns.svg',
    colour: '#401a63',
    oauthUrl: `https://oauth.bitbns.com/oauth/dialog/authorize?response_type=code&client_id=${process.env.REACT_APP_BIT_BNS_CLIENT_ID}&redirect_uri={{REDIRECT_URI}}&state={{STATE}}&scope=READONLY&code_challenge={{CODE_CHALLENGE}}&code_challenge_method=S256`,
    refreshUrl: `${API_ROOT_URL}payments/bit-bns/refresh`,
    paymentUrl: `${API_ROOT_URL}payments/bit-bns/payment-gateway/url`,
    pricing: '12 USDT/year',
  },
}
