import React from 'react'

import { Source, Ticker } from 'types'
import { Model } from 'types/models'
import AssetClass from 'types/assetClass'
import useMultiSeriesChart from 'hooks/useMultiSeriesChart'

import { SeriesPills } from '../common/series/Selector'
import MultiLineSeriesWrapper from '../common/series/MultiSeriesWrapper'
import useDerivedActiveListedExpiry from 'hooks/useDerivedActiveListedExpiry'
import ChartLoader from '../ChartLoader'

const EXPIRY_OPTIONS_STABLE = [
  {
    name: '1W',
    value: 7,
  },
  {
    name: '1M',
    value: 30,
  },
  {
    name: '3M',
    value: 90,
  },
]

// TODO: Renable for prod when data ok
const UNSTABLE_OPTIONS = [
  {
    name: '6M',
    value: 180,
  },
  {
    name: '1Y',
    value: 365,
  },
]
const EXPIRY_OPTIONS =
  process.env.REACT_APP_ENV === 'staging'
    ? EXPIRY_OPTIONS_STABLE.concat(UNSTABLE_OPTIONS)
    : EXPIRY_OPTIONS_STABLE
const activeDeltas = ['5delta', '10delta', '15delta', '20delta', '25delta']

const OptionVolatilityRatio: React.FC = () => {
  const title = 'Butterfly Spread'
  const { listedExpiries, isLoading } = useDerivedActiveListedExpiry({
    queryKeys: [`deribit.option.BTC.SVI.listed.1h.butterfly`],
    refetchInterval: Infinity,
    timestampRange: { range: '1d' }, // Short range just to get the possible expiries
    enabled: true,
    dataFreq: '1h',
    refetchOnWindowFocus: false,
  })

  const { chartSeries, highlighted, setHighlighted } = useMultiSeriesChart(
    title,
    [
      {
        assetClass: AssetClass.OPTION,
        middle: {
          model: Model.SVI,
          currency: Ticker.BTC,
          tenor: '30d',
          series: '10delta',
        },
        suffix: 'butterfly',
        source: Source.DERIBIT,
      },
      {
        assetClass: AssetClass.OPTION,
        middle: {
          model: Model.SVI,
          currency: Ticker.BTC,
          tenor: '7d',
          series: '5delta',
        },
        suffix: 'butterfly',
        source: Source.DERIBIT,
      },
      {
        isListedExpiry: true,
        expiry: listedExpiries[4],
        source: Source.DERIBIT,
        baseAsset: Ticker.BTC,
        model: Model.SVI,
        suffix: 'butterfly',
        field: '5delta',
      },
      {
        isListedExpiry: true,
        expiry: listedExpiries[2],
        source: Source.DERIBIT,
        baseAsset: Ticker.BTC,
        model: Model.SVI,
        suffix: 'butterfly',
        field: '10delta',
      },
    ],
  )

  return isLoading ? (
    <ChartLoader />
  ) : (
    <MultiLineSeriesWrapper
      chartSeries={chartSeries}
      title={title}
      id={title}
      axisLabel="%"
      axis={{ LEFT: 'p2' }}
      assetType={AssetClass.OPTION}
      series={activeDeltas}
      useTypeToggle
      suffix="butterfly"
      useTenors
      listedExpiry={{ enabled: true }}
      fetchListedExpiries
      seriesPills={
        <SeriesPills
          chartSeries={chartSeries}
          id={title}
          setHighlighted={setHighlighted}
        />
      }
      highlightedIndx={highlighted}
    />
  )
}

export default OptionVolatilityRatio
