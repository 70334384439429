import { getLayoutForCard } from 'components/molecules/Grid/layout'
import ItemContainer from 'pages/dashboards/ItemContainer'
import { createRef, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useLoaderData } from 'react-router-dom'
import { Dashboard, DefaultCharts, DefaultTables } from 'types/dashboard'

import { Body1, H4 } from 'components/atoms/Typography/styles'
import FutureHeatMap from 'components/charts/futures/FuturesHeatMap'
import OptionsHeatMap from 'components/charts/options/OptionsHeatMap'
import OptionsPutCallSkew from 'components/charts/options/OptionsPutCallSkew'
import PerpetualSwapsFundingRate from 'components/charts/perpetuals/PerpetualSwapsFunding'
import OptionPricer from 'pages/OptionPricer/OptionPricer'
import OptionsSmile from 'components/charts/options/OptionsSmile'
import OptionVolatilityRatio from 'components/charts/options/OptionsVolatilityRatio'
import { usePermissions } from 'hooks/usePermissions'

const ResponsiveGridLayout = WidthProvider(Responsive)

const DEFAULT_CHART_MAP: Partial<
  Record<DefaultCharts, (props: any) => JSX.Element>
> = {
  [DefaultCharts.HEATMAP_OPTION_IMPLIED_VOL]: (props) => (
    <OptionsHeatMap {...props} />
  ),
  [DefaultCharts.HEATMAP_FUTURE]: (props) => <FutureHeatMap {...props} />,
  [DefaultCharts.PERP_FUNDING_RATE]: (props) => (
    <PerpetualSwapsFundingRate {...props} />
  ),
  [DefaultCharts.OPTION_SKEW]: (props) => <OptionsPutCallSkew {...props} />,
  [DefaultCharts.OPTION_SMILE]: (props) => <OptionsSmile {...props} />,
  [DefaultCharts.OPTION_RATIO]: (props) => <OptionVolatilityRatio {...props} />,
}

const DEFAULT_TABLE_MAP: Partial<
  Record<DefaultTables, (props: any) => JSX.Element>
> = {
  [DefaultTables.OPTION_PRICER]: (props) => (
    <OptionPricer {...props} useDefaults />
  ),
}
const getDesktopLayout = (charts: Dashboard['items']) => {
  return charts?.map((c) => getLayoutForCard({ card: c, isStatic: false }))
}

const ResearchDashBoard = () => {
  const data = useLoaderData() as Dashboard
  const { EXPIRY_DATE, validUser } = usePermissions()

  const [layouts, setLayouts] = useState(() => ({
    desktop: getDesktopLayout(data?.items),
  }))

  if (!data?.id || data?.items?.length === 0) {
    return (
      <section
        style={{
          textAlign: 'center',
          height: '100%',
          maxWidth: '80%',
          margin: 'auto',
          padding: '2rem',
        }}
      >
        <H4 style={{ paddingBottom: '2rem' }}>
          Unable to find the requested research.
        </H4>
        <Body1>
          We apologize for the inconvenience. It seems that we&apos;re unable to
          locate the requested research dashboard at the moment. Please ensure
          that you have the correct link and try again. If you continue to
          experience difficulties, please don&apos;t hesitate to reach out to
          our support team for further assistance. Thank you for your
          understanding and patience.
        </Body1>
      </section>
    )
  }
  if (!EXPIRY_DATE && validUser) {
    return (
      <section
        style={{
          textAlign: 'center',
          height: '100%',
          maxWidth: '70%',
          margin: 'auto',
          padding: '2rem',
        }}
      >
        <H4 style={{ paddingBottom: '2rem' }}>
          Not authorized to view this dashboard.
        </H4>
        <Body1>
          We apologize for the inconvenience. It appears this dashboard may not
          align with your current permissions. If you&apos;ve recently created
          an account without selecting a subscription plan, please try logging
          out. If you believe this is an error or need assistance, please
          contact us at{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="mailto:support@blockscholes.com"
          >
            support@blockscholes.com
          </a>
        </Body1>
      </section>
    )
  }
  return (
    <ResponsiveGridLayout
      useCSSTransforms
      isDraggable={false}
      layouts={layouts}
      onLayoutChange={(_, layouts) => setLayouts(layouts)}
      breakpoints={{ desktop: 1200, mobile: 500 }}
      cols={{ desktop: 12, mobile: 4 }}
      rowHeight={30}
    >
      {data?.items?.map((c, i) => {
        const itemRef = createRef<HTMLDivElement>()

        const DefaultChartComp =
          c.type === 'table'
            ? DEFAULT_TABLE_MAP[c.defaultId || '']
            : DEFAULT_CHART_MAP[c.defaultId || '']
        if (!DefaultChartComp) return <></> // TODO Add custom charting here

        return (
          <ItemContainer key={c.id} ref={itemRef}>
            <DefaultChartComp {...c} />
          </ItemContainer>
        )
      })}
    </ResponsiveGridLayout>
  )
}

export default ResearchDashBoard
