/* eslint-disable no-param-reassign */
import { CHART_COLORS } from 'components/charts/common/consts'
import { ConstructKey, SmileKey } from 'components/charts/common/util'
import {
  ChartState,
  DefaultChartSlice,
  FutureTermStructureSeries,
  HeatMapState,
  NewChartSeries,
  NewChartSlice,
  NewChartState,
  SmileState,
  TermStructureState,
} from 'stores/types'
import {
  AxisFormat,
  Chart,
  PriceType,
  Source,
  Ticker,
  TimeZone,
  YAxis,
} from 'types'
import AssetClass from 'types/assetClass'
import { Model, SABRParamWithVol } from 'types/models'
import { StateCreator } from 'zustand'
import { immer } from 'zustand/middleware/immer'

const initialChartState: Omit<ChartState, 'axis'> = {
  type: Chart.TIMESERIES,
  timings: { range: '1M' },
  frequency: '1h',
  timeZone: TimeZone.UTC,
  series: undefined,
}
export const initialHeatmapChartState: HeatMapState = {
  type: Chart.HEATMAP,
  currency: Ticker.BTC,
  timestamp: 'LATEST',
  source: Source.DERIBIT,
  timeZone: TimeZone.UTC,
}

export const initialFutureTermStructure: TermStructureState = {
  type: Chart.TERM_STRUCTURE,
  assetClass: AssetClass.FUTURE,
  axis: {
    LEFT: 'p2',
  },
  timeZone: TimeZone.UTC,
  series: [
    {
      currency: Ticker.BTC,
      timestamp: 'LATEST',
      source: Source.DERIBIT,
      type: PriceType.YIELD,
      color: CHART_COLORS[0],
      axis: {
        yAxis: YAxis.LEFT,
      },
    },
    {
      currency: Ticker.ETH,
      timestamp: 'LATEST',
      source: Source.DERIBIT,
      type: PriceType.YIELD,
      color: CHART_COLORS[1],
      axis: {
        yAxis: YAxis.LEFT,
      },
    },
  ],
}
export const initialOptionTermStructure: TermStructureState = {
  type: Chart.TERM_STRUCTURE,
  assetClass: AssetClass.OPTION,
  axis: {
    LEFT: 'p2',
  },
  timeZone: TimeZone.UTC,
  series: [
    {
      currency: Ticker.BTC,
      timestamp: 'LATEST',
      source: Source.DERIBIT,
      model: {
        type: Model.SABR,
        param: SABRParamWithVol.Vol,
      },
      axis: {
        yAxis: YAxis.LEFT,
      },
      color: CHART_COLORS[0],
    },
    {
      currency: Ticker.ETH,
      timestamp: 'LATEST',
      source: Source.DERIBIT,
      model: {
        type: Model.SABR,
        param: SABRParamWithVol.Vol,
      },
      axis: {
        yAxis: YAxis.LEFT,
      },
      color: CHART_COLORS[1],
    },
  ],
}
export const initialSmileChart: SmileState = {
  type: Chart.SMILE,
  assetClass: AssetClass.OPTION,
  axis: {
    LEFT: 'p2',
  },
  timeZone: TimeZone.UTC,
  series: undefined,
}
const createDefaultChartSlice: StateCreator<
  DefaultChartSlice,
  [],
  [['zustand/immer', never]],
  DefaultChartSlice
> = immer((set, get) => ({
  defaultCharts: {},
  setInitialChart: ({ key, type, assetClass, series, axis }) => {
    const chart = get().defaultCharts?.[key]
    if (!chart) {
      set((state) => {
        if (type === Chart.HEATMAP) {
          state.defaultCharts[key] = {
            ...initialHeatmapChartState,
          }
        } else if (type === Chart.TERM_STRUCTURE) {
          if (assetClass === AssetClass.FUTURE) {
            if (series) {
              state.defaultCharts[key] = {
                ...initialFutureTermStructure,
                series: series as FutureTermStructureSeries[],
              }
            } else {
              state.defaultCharts[key] = { ...initialFutureTermStructure }
            }
            return
          }
          if (assetClass === AssetClass.OPTION) {
            state.defaultCharts[key] = initialOptionTermStructure
          }
        } else if (type === Chart.SMILE) {
          const c: SmileState = {
            ...initialSmileChart,
            series: series as SmileKey[],
            type,
          }
          if (axis) {
            c.axis = axis
          }
          state.defaultCharts[key] = c
        } else if (type === Chart.TIMESERIES_BAR) {
          const s: ChartState = {
            ...initialChartState,
            series: series as ConstructKey[],
            type,
            frequency: '1D',
            axis: {
              LEFT: 'n2',
            },
          }
          if (axis) {
            s.axis = axis
          }
          state.defaultCharts[key] = s
        } else {
          const s: ChartState = {
            ...initialChartState,
            series: series as ConstructKey[],
            type,
          } as ChartState
          if (axis) {
            s.axis = axis
          }
          state.defaultCharts[key] = s
        }
      })
    }
  },
  updateTimeSeriesChart: (chartKey, key, val) => {
    set((state) => {
      const chart = state.defaultCharts[chartKey]
      if (!val || !chart) return
      if (
        chart.type === Chart.TIMESERIES ||
        chart.type === Chart.TIMESERIES_BAR
      ) {
        chart[key] = val
      }
      if (
        (chart.type === Chart.TIMESERIES ||
          chart.type === Chart.TIMESERIES_BAR) &&
        key === 'frequency' &&
        val.toString().endsWith('m') &&
        'range' in chart.timings
      ) {
        chart.timings.range = '1d'
      }
      if (
        (chart.type === Chart.TIMESERIES ||
          chart.type === Chart.TIMESERIES_BAR) &&
        key === 'frequency' &&
        !val.toString().endsWith('m') &&
        'range' in chart.timings &&
        chart.timings.range === '1d'
      ) {
        chart.timings.range = '1M'
      }
    })
  },
  updateHeatMap: (chartKey, key, val) => {
    set((state) => {
      const chart = state.defaultCharts[chartKey]
      if (!val || !chart) return
      if (chart.type === Chart.HEATMAP) {
        chart[key] = val
      }
    })
  },
  updateTermStructure: (chartKey, key, val) => {
    set((state) => {
      const chart = state.defaultCharts[chartKey]
      if (!val || !chart) return
      if (chart.type === Chart.TERM_STRUCTURE) {
        chart[key] = val
      }
    })
  },
  updateSmile: (chartKey, key, val) => {
    set((state) => {
      const chart = state.defaultCharts[chartKey]
      if (!val || !chart) return
      if (chart.type === Chart.SMILE) {
        chart[key] = val
      }
    })
  },
  editSeries: (chartKey, { indx, key, val }) => {
    set((state) => {
      const chart = state.defaultCharts[chartKey]

      if (!val || !chart) return
      const series = 'series' in chart && chart.series?.[indx]
      if (!series) return
      if ('series' in chart && chart.series && chart.series[indx]) {
        if (key === 'axis' && chart.axis) {
          const format: AxisFormat = chart.axis[series.axis.yAxis] || 'n2'

          const k = val as ConstructKey['axis']
          const chartSeries = chart.series as unknown as ChartState['series']
          const seriesStillOnAxis = chartSeries?.find(
            (s, idx) => s.axis?.yAxis !== k?.yAxis && idx !== indx,
          )
          if (k?.yAxis === YAxis.LEFT && !chart.axis.LEFT) {
            chart.axis.LEFT = format
          }
          if (k?.yAxis === YAxis.RIGHT && !chart.axis.RIGHT) {
            chart.axis.RIGHT = format
          }
          if (!seriesStillOnAxis && k?.yAxis) {
            const oldAxis = k.yAxis === YAxis.LEFT ? YAxis.RIGHT : YAxis.LEFT
            chart.axis[oldAxis] = undefined
          }
        }

        chart.series[indx] = {
          ...chart.series[indx],
          [key]: val,
        }
      }
    })
  },
}))

export default createDefaultChartSlice

export const createNewChartSlice: StateCreator<
  NewChartSlice,
  [],
  [['zustand/immer', never]],
  NewChartSlice
> = immer((set, get) => ({
  defaultCharts: {},
  setInitialChart: ({ key, type, series, axis }) => {
    const chart = get().defaultCharts?.[key]
    if (!chart) {
      set((state) => {
        const s: NewChartState = {
          ...initialChartState,
          series: series as NewChartSeries[],
          type,
          frequency: type === Chart.TIMESERIES ? '1h' : '1D',
          axis: {
            LEFT: 'n2',
          },
        }
        if (axis) {
          s.axis = axis
        }
        state.defaultCharts[key] = s
      })
    }
  },
  updateTimeSeriesChart: (
    chartKey: string,
    obj: Partial<
      Record<keyof NewChartState, NewChartState[keyof NewChartState]>
    >,
  ) => {
    const entries = Object.entries(obj)

    set((state) => {
      const chart = state.defaultCharts[chartKey]
      if (entries?.length === 0 || !chart) return
      for (let i = 0; i < entries.length; i++) {
        const [key, val] = entries[i]
        if (!val) break
        chart[key] = val

        if (
          (chart.type === Chart.TIMESERIES ||
            chart.type === Chart.TIMESERIES_BAR) &&
          key === 'frequency' &&
          val.toString().endsWith('m') &&
          'range' in chart.timings
        ) {
          chart.timings.range = '1d'
        }
        if (
          (chart.type === Chart.TIMESERIES ||
            chart.type === Chart.TIMESERIES_BAR) &&
          key === 'frequency' &&
          !val.toString().endsWith('m') &&
          'range' in chart.timings &&
          chart.timings.range === '1d'
        ) {
          chart.timings.range = '1M'
        }
      }
    })
  },
  updateSeries: (chartKey, index, obj) => {
    const entries = Object.entries(obj)
    set((state) => {
      const chart = state.defaultCharts[chartKey]
      const series = chart.series?.[index]
      if (entries?.length === 0 || !series) return
      for (let i = 0; i < entries.length; i++) {
        const [key, val] = entries[i]
        if (!val) continue
        series[key] = val
      }

      if (
        chart?.axis.RIGHT &&
        !chart.series?.find((s) => s.axis.yAxis === YAxis.RIGHT)
      ) {
        delete state.defaultCharts[chartKey]?.axis.RIGHT
      }
      if (
        chart?.axis.LEFT &&
        !chart.series?.find((s) => s.axis.yAxis === YAxis.LEFT)
      ) {
        delete state.defaultCharts[chartKey]?.axis.LEFT
      }
    })
  },
}))
