import { TooltipContext } from '@progress/kendo-react-charts'
import Query from 'pages/HistoricalAnalyzer/query'
import AssetClass from './assetClass'
import { Source } from './source'
import { Ticker } from './ticker'

export interface ITimeSeriesDataPoint {
  x: number | Date
  y: number | string
}
export interface LiveDataPoint extends ITimeSeriesDataPoint {
  live?: boolean
  liveTime?: number
  increase?: number
}

export enum TimeZone {
  LOCAL = 'LOCAL',
  UTC = 'Etc/UTC',
}

export const CHART_KIND = ['timeseries', 'cdf', 'regression'] as const
type ChartKindTuple = typeof CHART_KIND
export type ChartKind = ChartKindTuple[number]

export type TimeSeriesData = {
  key: string
  dataPoints: Array<ITimeSeriesDataPoint | LiveDataPoint>
  field?: string
  kind?: ChartKind
}

export interface INumberSeriesDataPoint {
  x: number
  y: number
}

export type NumberSeriesData = Array<INumberSeriesDataPoint>

export type MultiTimeSeriesData = Array<TimeSeriesData>

export type HistoricalAnalyzerTimeSeriesData = {
  kind: 'timeseries'
  data: Array<ITimeSeriesDataPoint>
}

export type HistoricalAnalyzerRegressionData = {
  kind: 'regression'
  data: {
    scatter: NumberSeriesData
    line: NumberSeriesData
    alpha: number
    beta: number
    rSquared: number
  }
}

export type HistoricalAnalyzerCdfData = {
  kind: 'cdf'
  data: NumberSeriesData
  percentiles: Array<number>
}

export type HistoricalAnalyzerData =
  | HistoricalAnalyzerTimeSeriesData
  | HistoricalAnalyzerRegressionData
  | HistoricalAnalyzerCdfData

export type HistoricalAnalyzerTimeSeriesDataNew = {
  kind: 'timeseries'
  dataPoints: Array<ITimeSeriesDataPoint>
}

export type HistoricalAnalyzerRegressionDataNew = {
  kind: 'regression'
  dataPoints: {
    scatter: NumberSeriesData
    line: NumberSeriesData
    alpha: number
    beta: number
    rSquared: number
  }
}

export type HistoricalAnalyzerCdfDataNew = {
  kind: 'cdf'
  dataPoints: NumberSeriesData
  percentiles: Array<number>
}

export type HistoricalAnalyzerDataNew =
  | HistoricalAnalyzerTimeSeriesDataNew
  | HistoricalAnalyzerRegressionDataNew
  | HistoricalAnalyzerCdfDataNew

export type HistoricalAnalyzerQuerySeries = Map<Query, HistoricalAnalyzerData>

export type MultiHistoricalAnalyzerQueryResponse = {
  successes: HistoricalAnalyzerQuerySeries
  errors: Map<Query, string>
}

export type Frequency =
  | '1m'
  | '10m'
  | '15m'
  | '30m'
  | '1h'
  | '4h'
  | '8h'
  | '12h'
  | '1D'
  | '1W'
  | '1M'

export interface IChartFrequencyData {
  unit: 'minutes' | 'hours' | 'days' | 'weeks' | 'months'
  step: number
  duration?: number
  label: string
}
export type FrequencyMap = Record<Frequency, IChartFrequencyData>
export const TEMP_INSTRUMENTS = [
  '2SEP22',
  '23SEP22',
  '7OCT22',
  '14OCT22',
  '21OCT22',
  '28OCT22',
  '25NOV22',
  '30DEC22',
  '31MAR23',
] as const
type ConstantExpiryInstrumentsTuple = typeof TEMP_INSTRUMENTS
export type ConstantExpiryInstrument = ConstantExpiryInstrumentsTuple[number]

export type DateRange = {
  from: Date
  until: Date
}

export type TickerState = Partial<Record<Ticker, boolean>>

export const CONSTANT_TENORS = ['1W', '2W', '1M', '2M', '3M', '6M', '1Y']
const CONSTANT_TENORS_BY_DAYS = [
  '7d',
  '14d',
  '30d',
  '60d',
  '90d',
  '180d',
  '365d',
]
export const OPTION_TENORS: Record<Partial<Source>, ConstantTenorByDays[]> = {
  [Source.DERIBIT]: CONSTANT_TENORS_BY_DAYS,
  [Source.COMPOSITE]: CONSTANT_TENORS_BY_DAYS,
  [Source.LYRA_OPTIMISM]: CONSTANT_TENORS_BY_DAYS,
  [Source.LYRA_ARBITRUM]: CONSTANT_TENORS_BY_DAYS,
  [Source.BITMEX]: CONSTANT_TENORS_BY_DAYS,
  [Source.BYBIT]:
    process.env.REACT_APP_ENV === 'staging'
      ? CONSTANT_TENORS_BY_DAYS
      : ['7d', '14d', '30d', '90d'],
  [Source.AEVO]: CONSTANT_TENORS_BY_DAYS,
  [Source.OKX]: CONSTANT_TENORS_BY_DAYS,
}

type ConstantTenorsTuple = typeof CONSTANT_TENORS
type ConstantTenorsByDaysTuple = typeof CONSTANT_TENORS_BY_DAYS
export type ConstantTenor = ConstantTenorsTuple[number]
export type ConstantTenorByDays = ConstantTenorsByDaysTuple[number]

export type CurveBasis = 'annual' | 'basis'

export type TermStructure = Array<{
  instrument: string
  yield: number
  dateOffset: number
}>

export type MultiTermStructure = Record<string, TermStructure>

export type HistoricSVIParams = {
  Alpha: number
  Beta: number
  Rho: number
  Mu: number
  Sigma: number
}

export type SVIParams = HistoricSVIParams & {
  Vol: number
}

export type HistoricSABRParams = {
  Rho: number
  Alpha: number
  VolVol: number
}

export type SABRParams = HistoricSABRParams & {
  Vol: number
}

export type HistoricImpliedVolParams = HistoricSVIParams | HistoricSABRParams

export type ModelParameters = SVIParams | SABRParams

export type HistoricImpliedVolParamsWithDate = HistoricImpliedVolParams & {
  dateOffset: number
  timestamp: Date
}

export type ModelParametersWithDate = ModelParameters & {
  dateOffset: number
  timestamp: Date
}

export type HistoricImpliedVolSeries = {
  key: string
  dataPoints: Array<HistoricImpliedVolParamsWithDate>
}

export type ModelParametersSeries = {
  key: string
  dataPoints: Array<ModelParametersWithDate>
}

export type MultiHistoricImpliedVolSeries = Record<
  string,
  Array<HistoricImpliedVolParamsWithDate>
>

export type MultiModelParametersSeries = Record<
  string,
  Array<ModelParametersWithDate>
>

export type TenorDay = number

export type HeatMapData = {
  strike: string
  expiry: number
  value: number
  zscore: number
  timeseries?: { series: string; field: string; start: string; end: string }
}

export type FuturesHeatMapEntry = {
  instrument: string
  expiry: Date | null
  availableSince: Date | null
  active?: boolean
  px?: number
  cells?: Array<number>
  timeseries?: string
}

export type FuturesHeatMap = Array<FuturesHeatMapEntry>

export type FutureHeatMapDataPoint = {
  xInstrument: string
  yInstrument: string
  value: number
  timeseries?: string
  zScore: number
}

export enum OptionsSmileSeriesType {
  MODEL,
  TENOR,
  DATE,
  DEFAULT,
}

export type LookBackWindow = '1Y' | '6M' | '3M' | '1M' | '7d' | '1d'

export enum Chart {
  TIMESERIES = 'TIMESERIES',
  TIMESERIES_BAR = 'TIMESERIES_BAR',
  TIMESERIES_AREA = 'TIMESERIES_AREA',
  HEATMAP = 'HEATMAP',
  SMILE = 'SMILE',
  TERM_STRUCTURE = 'TERM_STRUCTURE',
  CDF = 'CDF',
  REGRESSION = 'REGRESSION',
}

export type SeriesConstruct = {
  asset: AssetClass
  currency: Ticker
  seriesType?: string
  suffix: string
  tenor: string
  source: Source
  key?: string
}

export type SeriesTypeSelect = {
  enabled: boolean
  value: string | string[]
}

export enum YAxis {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type AxisFormat = `n${number}` | `c${number}` | `p${number}`

export interface Tooltip extends TooltipContext {
  labelFormat: string
}
