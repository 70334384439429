import { CheckIcon } from '@radix-ui/react-icons'
import styled, { useTheme } from 'styled-components'

import * as ScrollArea from '@radix-ui/react-scroll-area'
import { ItemText, SelectItem, SelectItemIndicator } from './Index'

const ScrollAreaRoot = styled(ScrollArea.Root)`
  //   width: 200px;
  height: 225px;
  border-radius: 4px;
  overflow: hidden;
  --scrollbar-size: 10px;
`
const ScrollAreaViewport = styled(ScrollArea.Viewport)`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  padding: 0 10px;

  * > .hover:hover {
    transform: scale(1.03);
  }
`

const VirtualInnerList = ({ data }: { data: string[] }) => {
  const theme = useTheme()
  return (
    <ScrollAreaRoot className="ScrollAreaRoot">
      <ScrollAreaViewport>
        {data.map((d) => {
          return (
            <SelectItem value={d} key={d}>
              <SelectItemIndicator>
                <CheckIcon />
              </SelectItemIndicator>
              <ItemText>{d}</ItemText>
            </SelectItem>
          )
        })}
      </ScrollAreaViewport>
      <ScrollArea.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="vertical"
        style={{
          width: '10px',
          background: theme.palette.common.blue8,
          display: 'flex',
        }}
      >
        <ScrollArea.Thumb
          style={{
            background: theme.palette.common.blue11,
            flex: 1,
            borderRadius: '10px',
            position: 'relative',
          }}
        />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner className="ScrollAreaCorner" />
    </ScrollAreaRoot>
  )
}

export default VirtualInnerList
