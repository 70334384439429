import { ListedSeries, SeriesItem } from 'stores/types'
import { AxisFormat, YAxis } from 'types'
import { CHART_COLORS } from '../consts'
import { ConstructKey } from '../util'

export const getDefaultSeries = <
  T extends Omit<ConstructKey, 'color' | 'axis'>,
>(
  series: (T | Omit<ListedSeries, 'color' | 'axis'>)[],
): SeriesItem[] =>
  series.reduce((acc: SeriesItem[], s, indx) => {
    if (
      ('isListedExpiry' in s && !s.expiry) ||
      ('middle' in s && 'expiry' in s.middle && !s.middle.expiry)
    ) {
      return acc
    }
    acc.push({
      color: CHART_COLORS[indx],
      axis: {
        yAxis: YAxis.LEFT,
      },
      ...s,
    })
    return acc
  }, [])

export const getSeriesAxis = ({
  id,
  chartAxis,
  format,
  updateAxis,
  axisToAddTo,
}: {
  id: string
  chartAxis: Partial<Record<YAxis, AxisFormat>>
  format: AxisFormat
  updateAxis: (axis: Partial<Record<YAxis, AxisFormat>>) => void
  axisToAddTo: YAxis
}) => {
  let axis
  if (axisToAddTo) {
    if (chartAxis[axisToAddTo]) {
      if (chartAxis[axisToAddTo]?.slice(0, 1) !== format?.slice(0, 1)) {
        axis = 'NONE'
      } else {
        axis = axisToAddTo
      }
    } else if (axisToAddTo && !chartAxis[axisToAddTo]) {
      updateAxis({
        ...chartAxis,
        [axisToAddTo]: format,
      })
      axis = axisToAddTo
    }
    return axis
  }
  return axis
}
