import { useEffect, useMemo, useRef, useState, memo } from 'react'
import {
  Cross2Icon,
  MixerHorizontalIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@radix-ui/react-icons'
import * as Popover from '@radix-ui/react-popover'
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  Table as TSTable,
  Column,
  Row,
  RowData,
  flexRender,
  ColumnFiltersState,
  SortingState,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  Cell,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useTheme } from 'styled-components'
import Button from '../Button'
import { useMediaQuery } from 'hooks/useMediaQuery'

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    type?: string
  }
}

const TableCell = <T,>({
  cell,
  spot,
}: {
  cell: Cell<T, unknown>
  spot?: number
}) => {
  let className = ''
  if (
    cell.column.columnDef.id === 'strike' ||
    cell.column.columnDef.id === 'contract' ||
    cell.column.columnDef.id === 'tenor'
  ) {
    className += 'strike'
  }
  if (
    spot &&
    ((cell.row.original[0] <= spot &&
      cell.column.columnDef.meta?.type === 'C') ||
      (cell.row.original[0] >= spot &&
        cell.column.columnDef.meta?.type === 'P'))
  ) {
    className += 'itm'
  }
  return (
    <td className={className}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )
}

const TableRow = <T,>({ row, spot }: { row: Row<T>; spot?: number }) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (
    <tr
      className="table-row"
      style={{ fontSize: isMobile ? '0.5rem' : 'max(.7rem,.7vw)' }}
    >
      {row.getVisibleCells().map((cell) => {
        return <TableCell key={cell.id} cell={cell} spot={spot} />
      })}
    </tr>
  )
}

const MemoizedRow = memo(TableRow) as typeof TableRow
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input
      onClick={(e) => e.stopPropagation()}
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}
function Filter<T>({
  column,
  table,
}: {
  column: Column<any, unknown>
  table: TSTable<T>
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue() as [number, number] | string
  const facetedUniqueValues = column.getFacetedUniqueValues()

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(facetedUniqueValues.keys()).sort(),
    [facetedUniqueValues, firstValue],
  )
  const theme = useTheme()
  if (typeof firstValue === 'number') {
    return (
      <div>
        <div style={{ padding: '1rem 0' }}>
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={columnFilterValue?.[0] ?? ''}
            onChange={(value) =>
              column.setFilterValue((old: [number, number]) => [
                value,
                old?.[1],
              ])
            }
            placeholder={`Min ${
              column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ''
            }`}
            className="border rounded"
            style={{
              width: '5rem',
              backgroundColor: theme.palette.common.blue6,
              color: theme.palette.common.white,
            }}
          />
          <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={columnFilterValue?.[1] ?? ''}
            onChange={(value) =>
              column.setFilterValue((old: [number, number]) => [
                old?.[0],
                value,
              ])
            }
            placeholder={`Max ${
              column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ''
            }`}
            className="border rounded"
            style={{
              width: '5rem',
              backgroundColor: theme.palette.common.blue6,
              color: theme.palette.common.white,
            }}
          />
        </div>
        {(columnFilterValue?.[0] || columnFilterValue?.[1]) && (
          <Button
            variant="menu"
            underline
            onClick={() => column.setFilterValue(() => [undefined, undefined])}
          >
            Reset
          </Button>
        )}
        <div className="h-1" />
      </div>
    )
  }
  if (facetedUniqueValues.size <= 2) {
    return (
      <>
        {sortedUniqueValues.map((s) => (
          <button>{s}</button>
        ))}
      </>
    )
  }
  return (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 50).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        list={`${column.id}list`}
        className="border rounded"
        style={{
          backgroundColor: theme.palette.common.blue6,
          color: theme.palette.common.white,
          margin: '0.5rem 0',
          padding: '0.5rem',
        }}
      />
      {columnFilterValue && (
        <Button
          variant="menu"
          underline
          onClick={() => column.setFilterValue(() => undefined)}
        >
          Reset
        </Button>
      )}
      <div className="h-1" />
    </>
  )
}

export default function Table<T>({
  columns,
  data,
  initialSortedColumn = 'contract',
  spot,
  resetFiltersKey,
}: {
  columns: ColumnDef<T, any>[]
  data: T[]
  initialSortedColumn?: string
  spot?: number
  resetFiltersKey?: string
}) {
  const initialSort = { id: initialSortedColumn, desc: false }
  const rowIndices = useRef<string>('')
  const tableContainerRef = useRef<HTMLTableElement>(null)
  const [sorting, setSorting] = useState<SortingState>([initialSort])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  useEffect(() => {
    if (resetFiltersKey) {
      setColumnFilters([])
      setSorting([initialSort])
    }
  }, [resetFiltersKey])

  const table = useReactTable<T>({
    columns,
    data,
    state: {
      sorting,
      columnFilters,
    },
    autoResetPageIndex: false,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel<T>(),
    getSortedRowModel: getSortedRowModel<T>(),
    getFilteredRowModel: getFilteredRowModel<T>(),
    getFacetedRowModel: getFacetedRowModel<T>(),
    getFacetedUniqueValues: getFacetedUniqueValues<T>(),
  })

  const { rows } = table.getRowModel()
  const rowsIndex = rows.map((r) => r.index)
  if (rowsIndex.join('') !== rowIndices.current && table.getPageCount() > 1) {
    rowIndices.current = rowsIndex.join('')
  }
  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    overscan: 20,
    estimateSize: () => 45,
  })
  const virtualRows = virtualizer.getVirtualItems()
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0

  const paddingBottom =
    virtualRows.length > 0
      ? virtualizer.getTotalSize() -
        (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0
  const theme = useTheme()
  return (
    <div
      ref={tableContainerRef}
      style={{
        height: `90%`,
        overflow: 'auto', // Make it scroll
      }}
    >
      <table>
        <thead
          className="table-head"
          style={{
            position: 'sticky',
            margin: 0,
            top: '-1px',
            color: 'white',
            fontSize: 'max(.7rem,.7vw)',
            padding: '1rem',
            backgroundColor: theme.palette.common.blue4,
            textAlign: 'center',
            textTransform: 'capitalize',
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, i) => {
                  const isEvenGroupChild =
                    (table
                      .getHeaderGroups()[0]
                      .headers.find(
                        (h) => header.column.parent?.id === h.column.id,
                      )?.index || 0) %
                      2 ===
                    1
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                      className={
                        (headerGroup.depth === 0 &&
                          !header.isPlaceholder &&
                          header.index % 2 === 1) ||
                        (header.column.parent && isEvenGroupChild)
                          ? 'group'
                          : ''
                      }
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <button
                            type="button"
                            disabled={header.depth === 0}
                            style={{ padding: '0 0.2rem' }}
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {header.column.columnDef.header === 'strike' ? (
                              <div>TEST</div>
                            ) : (
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )
                            )}
                            {{
                              asc: <TriangleUpIcon />,
                              desc: <TriangleDownIcon />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </button>
                          {header.column.getCanFilter() ? (
                            <Popover.Root>
                              <Popover.Trigger asChild>
                                <MixerHorizontalIcon
                                  onClick={(e) => e.stopPropagation()}
                                  height={12}
                                />
                              </Popover.Trigger>
                              <Popover.Portal>
                                <Popover.PopoverContent
                                  onClick={(e) => e.stopPropagation()}
                                  style={{
                                    backgroundColor: theme.palette.common.blue4,
                                    padding: '1rem',
                                  }}
                                >
                                  <Popover.PopoverClose
                                    className="PopoverClose"
                                    aria-label="Close"
                                    style={{
                                      position: 'absolute',
                                      right: '8px',
                                      top: 0,
                                      color: 'white',
                                    }}
                                  >
                                    <Cross2Icon />
                                  </Popover.PopoverClose>
                                  <Filter
                                    column={header.column}
                                    table={table}
                                  />
                                  <Popover.Arrow className="PopoverArrow" />
                                </Popover.PopoverContent>
                              </Popover.Portal>
                            </Popover.Root>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            )
          })}
        </thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index]
            return <MemoizedRow key={virtualRow.index} row={row} spot={spot} />
          })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
      {/* {table.getPageCount() - 1 > 0 && (
        <div className="flex items-center gap-2">
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
        </div>
      )} */}
    </div>
  )
}
