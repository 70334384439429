import { H6 } from 'components/atoms/Typography/styles'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import { compareAsc, format } from 'date-fns'

import { CatalogContext } from 'context/CatalogContext'
import { useDefaultStore, useLiveStore } from 'stores'
import { IVData, KeyData, LiveActionTypes, LiveState } from 'stores/types'
import { Source } from 'types'
import { Monitors } from 'types/Monitors'

import ListedExpiryMonitor from './ListedExpiry'
import ExpiryPicker from './ExpiryPicker'

const monitor = Monitors.MARKET_OPTIONS

const OptionsMonitor = () => {
  const catalog = useContext(CatalogContext)
  const exchange = useDefaultStore((s) => s.monitors.exchange)
  const ccy = useDefaultStore((s) => s.monitors.currency)
  const dispatch = useLiveStore((s) => s.actions.dispatch)
  const expiry = useLiveStore((s) => s.monitors[monitor] || '')
  const optionWithExpiry = useMemo(
    () =>
      catalog.state.activeOptions.find((o) =>
        o.instrument.startsWith(`${ccy.toUpperCase()}-${expiry}-`),
      ),
    [catalog.state.activeOptions, ccy, expiry],
  )
  const dataCallback = useCallback(
    (s: LiveState) => {
      const keys = Object.keys(s.data)
      const filteredKeys = keys.filter((k) =>
        k.includes(`${exchange.toLowerCase()}.${ccy}-${expiry}-`),
      )
      return Object.entries(
        filteredKeys.reduce(
          (
            acc: Record<number, { C?: KeyData; P?: KeyData; IV?: IVData }>,
            id,
          ) => {
            const strike = Number(id.split('-')[2])
            if (!acc[strike]) {
              acc[strike] = { [id.endsWith('-C') ? 'C' : 'P']: s.data[id] }
            } else if (id.endsWith('-C')) {
              acc[strike].C = { ...acc[strike].C, ...s.data[id] }
            } else if (id.endsWith('-P')) {
              acc[strike].P = { ...acc[strike].P, ...s.data[id] }
            }
            return acc
          },
          {},
        ),
      )
    },
    [exchange, ccy, expiry],
  )

  const data = useLiveStore(dataCallback, shallow)

  useEffect(() => {
    dispatch({
      type: LiveActionTypes.SUBSCRIBE,
      data: {
        streams: [
          {
            monitor: {
              id: `monitor.${exchange as Source}.${monitor}`,
              expiries: [expiry || 'default'],
            },
          },
        ],
      },
    })
  }, [dispatch, exchange, expiry])

  useEffect(() => {
    let timer

    if (!optionWithExpiry) {
      const newExpiry = [
        ...new Set(catalog.state.activeOptions.map((o) => o.expiry)),
      ].sort((a, b) => compareAsc(new Date(a), new Date(b)))?.[0]
      if (newExpiry) {
        dispatch({
          type: LiveActionTypes.SUBSCRIBE,
          data: {
            streams: [
              {
                monitor: {
                  id: `monitor.${exchange as Source}.${monitor}`,
                  expiries: [
                    format(new Date(newExpiry), 'ddLLLyy').toUpperCase(),
                  ],
                },
              },
            ],
          },
        })
      }
    } else {
      const expiryDiff =
        new Date(optionWithExpiry?.expiry).getTime() - new Date().getTime()
      if (expiryDiff < 14400000) {
        //4hrs
        timer = setInterval(() => {
          if (new Date(optionWithExpiry?.expiry) < new Date()) {
            const newExpiry = [
              ...new Set(catalog.state.activeOptions.map((o) => o.expiry)),
            ].sort((a, b) => compareAsc(new Date(a), new Date(b)))?.[0]

            if (newExpiry) {
              dispatch({
                type: LiveActionTypes.SUBSCRIBE,
                data: {
                  streams: [
                    {
                      monitor: {
                        id: `monitor.${exchange as Source}.${monitor}`,
                        expiries: [
                          format(new Date(newExpiry), 'ddLLLyy').toUpperCase(),
                        ],
                      },
                    },
                  ],
                },
              })
            }
          }
        }, 1000 * 60 * 15)
      }
    }
    return () => {
      clearInterval(timer)
    }
  }, [optionWithExpiry, catalog.state.activeOptions, dispatch, exchange])

  useEffect(() => {
    return () =>
      dispatch({
        type: LiveActionTypes.UN_SUBSCRIBE,
        data: {
          streams: [`monitor.${exchange as Source}.${monitor}`],
        },
      })
  }, [])

  return (
    <ListedExpiryMonitor
      data={data}
      expiry={expiry}
      topBar={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <H6 style={{ padding: '0.5rem' }}>Market Options</H6>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: '0' }}>Expiry:</p>
            <ExpiryPicker expiry={expiry} />
          </span>
        </div>
      }
    />
  )
}

export default OptionsMonitor
