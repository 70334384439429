import { ConstructKey, SmileKey } from 'components/charts/common/util'
import {
  AxisFormat,
  Chart,
  DateRange,
  Frequency,
  LookBackWindow,
  PriceType,
  Source,
  Ticker,
  TimeZone,
  YAxis,
} from 'types'
import AssetClass from 'types/assetClass'
import { Instrument, QuoteAsset } from 'types/catalog'
import { Model, SABRParamWithVol, SVIParamWithVol } from 'types/models'
import { Monitors } from 'types/Monitors'
import { AnalyseScenario, EditableOptionPriceQuery } from 'types/queries'

export type ListedSeries = {
  isListedExpiry: true
  expiry: string
  suffix:
    | 'params'
    | 'smile'
    | 'skew'
    | 'butterfly'
    | 'moneyness'
    | 'vol.ratio'
    | 'vol.realized'
    | 'vol.atm'
  field: string
  color: string
  source: Source
  baseAsset: Ticker
  model: Model
  axis: {
    yAxis: YAxis
  }
}
export type SeriesItem = Omit<ConstructKey, 'frequency'> | ListedSeries
export interface ChartState {
  type: Exclude<Chart, Chart.HEATMAP | Chart.TERM_STRUCTURE | Chart.SMILE>
  timings: { single?: 'LATEST' | Date; range?: LookBackWindow | DateRange }
  frequency: Frequency
  series?: SeriesItem[]
  axis: Partial<Record<YAxis, AxisFormat>>
  timeZone?: TimeZone
}

type FutureHeatMap = {
  assetClass?: AssetClass.FUTURE
  type: Chart.HEATMAP
  timestamp: Date | 'LATEST'
  currency: Ticker
  frequency?: Frequency
  source: Source
  timeZone: TimeZone
}
export type OptionHeatMap = {
  assetClass?: AssetClass.OPTION
  type: Chart.HEATMAP
  timestamp: Date | 'LATEST'
  currency: Ticker
  frequency?: Frequency
  source: Source
  model: Model
  timeZone: TimeZone
}

export type FutureTermStructureSeries = {
  timestamp: Date | 'LATEST'
  currency: Ticker
  source: Source
  type: PriceType.PRICE | PriceType.YIELD
  axis: {
    yAxis: YAxis
  }
  color: string
}

export type FutureTermStructure = {
  assetClass: AssetClass.FUTURE
  type: Chart.TERM_STRUCTURE
  axis: Partial<Record<YAxis, AxisFormat>>
  timeZone: TimeZone
  series: FutureTermStructureSeries[]
}
export interface OptionSingleSeries {
  timestamp: Date | 'LATEST'
  currency: Ticker
  source: Source
  model:
    | {
        type: Model.SABR
        param: SABRParamWithVol
      }
    | {
        type: Model.SVI
        param: SVIParamWithVol
      }
  axis: {
    yAxis: YAxis
  }
  color: string
}

export type OptionTermStructure = {
  assetClass: AssetClass.OPTION
  type: Chart.TERM_STRUCTURE
  axis: Partial<Record<YAxis, AxisFormat>>
  series: OptionSingleSeries[]
  timeZone?: TimeZone
}

export type SmileState = {
  assetClass: AssetClass.OPTION
  type: Chart.SMILE
  axis: Partial<Record<YAxis, AxisFormat>>
  timeZone?: TimeZone
  series?: SmileKey[]
}
export type TermStructureState = FutureTermStructure | OptionTermStructure

export type HeatMapState = FutureHeatMap | OptionHeatMap

export type UpdateTermStructure = <K extends keyof TermStructureState>(
  chartKey: string,
  key: K,
  val: TermStructureState[K],
) => void
export type UpdateTimeSeries = <K extends keyof ChartState>(
  chartKey: string,
  key: K,
  val: ChartState[K],
) => void
export interface DefaultChartSlice {
  defaultCharts: Record<
    string,
    ChartState | HeatMapState | TermStructureState | SmileState
  >
  setInitialChart: ({
    key,
    type,
    assetClass,
  }: {
    key: string
    type: Chart
    assetClass?: AssetClass
    series?:
      | (Omit<ConstructKey, 'frequency'> | ListedSeries)[]
      | FutureTermStructureSeries[]
      | SmileKey[]
    axis?: Partial<Record<YAxis, AxisFormat>>
  }) => void
  updateTimeSeriesChart: UpdateTimeSeries
  updateHeatMap: <K extends keyof HeatMapState>(
    chartKey: string,
    key: K,
    val: HeatMapState[K],
  ) => void
  updateTermStructure: UpdateTermStructure
  updateSmile: <K extends keyof SmileState>(
    chartKey: string,
    key: K,
    val: SmileState[K],
  ) => void
  editSeries: <K extends keyof ConstructKey>(
    chartKey: string,
    v: { indx: number; key: K; val: ConstructKey[K] },
  ) => void
}

export interface ExpandedChartSlice {
  expandedId: string | null
  setExpandedChart: (id: string | null) => void
}

export interface OptionPricerSlice {
  options: {
    queries: EditableOptionPriceQuery[]
    updateOption: (query: EditableOptionPriceQuery, i: number) => void
    setAnalysisLadder: (l: AnalyseScenario) => void
    setAnalysisFetched: () => void
    setAnalysisCurrency: (t: Ticker) => void
    addOption: () => void
    setDefaults: (queries: EditableOptionPriceQuery[]) => void
    deleteOption: (i: number) => void
    analysis: {
      fetched: boolean
      ladder: AnalyseScenario
      currency: Ticker | null
    }
  }
}

type TooltipData = {
  clientX: number
  clientY: number
  category: Date
  value: number
  series: string
  quote?: QuoteAsset
  isLivePoint?: boolean
  chartId?: string
  format?: string
}
export interface TooltipSlice {
  tooltip: {
    hoveredSeries: null | TooltipData
    setHoveredSeries: (s: null | TooltipData) => void
  }
}
export enum LiveActionTypes {
  SUBSCRIBE,
  UN_SUBSCRIBE,
  UPDATE_DATA,
  SET_READY,
  AUTHENTICATE,
  CLOSE,
  SET_MONITOR_IDS,
}

export type LiveDispatchBsLang = {
  type: string
  name: string
  args?: LiveDispatchBsLang[]
  client_id?: string
}

export type LiveDataDispatch =
  | {
      type: LiveActionTypes.SET_READY
    }
  | {
      type: LiveActionTypes.CLOSE
    }
  | {
      type: LiveActionTypes.SUBSCRIBE
      data: { keys: { qualified_name: string }[]; bslang?: string[] }
    }
  | {
      type: LiveActionTypes.SUBSCRIBE
      data: {
        streams: {
          monitor: { id: `monitor.${Source}.${Monitors}`; expiries?: string[] }
        }[]
      }
    }
  | {
      type: LiveActionTypes.UN_SUBSCRIBE
      data: {
        streams: string[]
      }
    }
  | {
      type: LiveActionTypes.UPDATE_DATA
      data: { id: string; timestamp: number; value: number }
    }
  | {
      type: LiveActionTypes.AUTHENTICATE
    }
  | {
      type: LiveActionTypes.SET_MONITOR_IDS
      data: {
        monitor: Monitors
        ids: string[]
      }
    }
export type LivePoint = { t: number; rt?: number; v: number; pv?: number } // rt = starting point of look back window, pv = previous value
export type LiveDataKey =
  | 'p'
  | 'pd24'
  | 'oi'
  | 'atm'
  | 'vv'
  | 'rho'
  | 'skew'
  | 'fly'
export type KeyData = Partial<Record<LiveDataKey, LivePoint>>
export type IVData = Partial<Record<'iv' | 'iv30dmax' | 'iv30dmin', LivePoint>>
export type DataKey = `${Source}.${Instrument}`

export interface LiveState {
  data: Record<DataKey, KeyData>
  monitors: Partial<
    { [Monitors.MARKET_OPTIONS]: string } & Record<
      keyof Omit<Monitors, Monitors.MARKET_OPTIONS>,
      Array<DataKey>
    >
  >
  bslangMap: Record<string, string>
  subscribed: Record<string, number>
  toSubscribe: Array<
    | { qualified_name: string }
    | Record<string, any>
    | { monitor: { id: string; expiries?: string[] } }
  >
  unSubscribe: string[]
  ready: boolean // ready to send messages
  authenticated: boolean // authenticated to ws
  open: boolean // ws open
  actions: {
    dispatch: (a: LiveDataDispatch) => void
  }
}

export interface MonitorsSlice {
  monitors: {
    currency: Ticker
    exchange: Omit<Source, Source.COMPOSITE>
    setCurrency: (ccy: Ticker) => void
    setExchange: (s: Omit<Source, Source.COMPOSITE>) => void
  }
}

type BSLangExpression = string
export type NewChartSeries = {
  expression?: BSLangExpression
  qualifiedName: string
  listed: boolean
  quote: QuoteAsset
  base: Ticker
  expiry?: string
  axis: {
    yAxis: YAxis
  }
  color: string
}
interface BaseAddSeries {
  source: Source
  ccy: Ticker | `${Ticker}/${QuoteAsset}`
  colour: string
  yAxis: YAxis
}
export interface InstrumentAddSeries extends BaseAddSeries {
  instrument?: Instrument
}
export interface ExpiryAddSeries extends BaseAddSeries {
  expiry?: string
}

type AddSeries = InstrumentAddSeries | ExpiryAddSeries
export type NewChartState = {
  type: Chart
  timings: { single?: 'LATEST' | Date; range?: LookBackWindow | DateRange }
  frequency: Frequency
  series?: NewChartSeries[]
  axis: Partial<Record<YAxis, AxisFormat>>
  timeZone?: TimeZone
  add?: AddSeries
}

export interface NewChartSlice {
  defaultCharts: Record<string, NewChartState>
  updateTimeSeriesChart: (
    chartKey: string,
    obj: Partial<
      Record<keyof NewChartState, NewChartState[keyof NewChartState]>
    >,
  ) => void
  updateSeries: (
    chartKey: string,
    index: number,
    obj: Partial<
      Record<keyof NewChartSeries, NewChartSeries[keyof NewChartSeries]>
    >,
  ) => void
  setInitialChart: ({
    key,
    type,
    series,
    axis,
  }: // assetClass,
  {
    key: string
    type: Chart
    axis: NewChartState['axis']
    // assetClass?: AssetClass
    series?: NewChartSeries[]
  }) => void
}
