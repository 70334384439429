import { useTheme } from 'styled-components'
import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'

import { LivePrices, Price, tickers } from './useLivePrices'

const LiveDataFeed: React.FC<{ prices: LivePrices }> = ({
  prices,
}: {
  prices: LivePrices
}) => {
  const theme = useTheme()
  const order =
    document.body.clientWidth < 1300
      ? tickers.slice(0, 5)
      : document.body.clientWidth < 1370
      ? tickers.slice(0, 6)
      : document.body.clientWidth < 1500
      ? tickers.slice(0, 7)
      : tickers

  const orderedPrices: Price[] = Object.entries(prices).reduce(
    (acc, [ticker, p]) => {
      if (!p.price) return acc
      const indx = order.findIndex((o) => o === ticker)
      acc[indx] = p
      return acc
    },
    new Array(order?.length),
  )

  const getDecimals = (price: number) => (price < 10 ? 3 : 2)
  return (
    <div className="price-bar-wrapper">
      {orderedPrices.map((p) => {
        return (
          <div
            style={{
              padding: '0 0.5rem',
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
            }}
            key={p.label}
          >
            <p
              style={{
                padding: 0,
                fontSize: '16px',
                textTransform: 'uppercase',
              }}
            >
              {p.label}
            </p>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  fontSize: '16px',
                  color:
                    p.price > (p?.prevPrice || 0)
                      ? theme.palette.common.lightGreen
                      : theme.palette.common.lightRed,
                }}
              >
                {p?.price?.toFixed(getDecimals(p?.price)).toString()}
                {p?.price > (p?.prevPrice || 0) ? (
                  <TriangleUpIcon
                    style={{ color: theme.palette.common.lightGreen }}
                  />
                ) : (
                  <TriangleDownIcon
                    style={{ color: theme.palette.common.lightRed }}
                  />
                )}
              </p>
              <p
                style={{
                  transform: 'scale(0.85)',
                }}
              >
                {p?.prevPrice?.toFixed(2).toString()}
              </p>
            </span>
            <div />
          </div>
        )
      })}
    </div>
  )
}

export default LiveDataFeed
